import React, {useState, useEffect, useReducer} from 'react';
import ReactDOM from 'react-dom';
import AvatarEditor from 'react-avatar-editor'

import ws from './helper/ws';
import cocktail from './helper/cocktail';

import styles from './css/ImageSet.css';
import fastyles from './fa/css/all.css';

const ImageSet = ({ recipe = {}, order = {}, hideModal }) => {
    const parent = recipe.id ? recipe : order;
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [scale, setScale] = useState(1);
    const [radius, setRadius] = useState(500);
    const [rotate, setRotate] = useState(0);
    const [position, setPosition] = useState({"x" : 0.5, "y" : 0.5});
    const [editor, setEditor] = useState(null);
    const [pickVisible, setPickVisible] = useState(parent.image ? false : true);
    const imageId = recipe.id ? recipe.slug : order.id;

    useEffect(() => {
        if(recipe.image) {
            setImage(ws.getEndpoint(`/recipes/${recipe.id}/image`));
        } else if(order.image) {
            setImage(ws.getEndpoint(`/orders/${order.id}/image`));
        }

        if(parent.icon) {
            setPosition(parent.icon.position);
            setRotate(parent.icon.rotate);
            setScale(parent.icon.scale);
            document.getElementById(`rotate-${imageId}`).value = parent.icon.rotate;
            document.getElementById(`scale-${imageId}`).value = parent.icon.scale;
        }
    }, []);

    const handleFileSelect = event => {
        setFile(event.target.files[0]);
        setImage(event.target.files[0]);
        setPickVisible(false);
    }

    const handleScale = event => {
        setScale(parseFloat(event.target.value));
    }

    const handleRadius = event => {
        setRadius(parseFloat(event.target.value));
    }

    const handleRotate = event => {
        setRotate(parseFloat(event.target.value));
    }

    const setEditorRef = e => {
        setEditor(e);
    }

    const saveImage = () => {
        const preview = editor.getImageScaledToCanvas().toDataURL();
        cocktail.uploadImage({
            "originalFileField" : file,
            "iconData" : preview,
            "originalFilename" : (() => {
                if(recipe.image) {
                    return recipe.image.filename;
                } else if(order.image) {
                    return order.image.filename;
                } else {
                    return null;
                }
            })(),
            "fileId" : recipe.id ? recipe.slug : order.id,
            "documentId" : recipe.id ? recipe.id : order.id,
            "documentType" : recipe.id ? "recipe" : "order",
            "position" : position,
            "rotate" : rotate,
            "scale" : scale
        }).then(() => {
            hideModal();
        }).catch(e => {
            console.error(e);
        });
    }

    const resetModal = () => {
        setImage(null);
        setFile(null);
        setScale(1);
        setRotate(0);
        setPosition({
            "x" : 0.5,
            "y" : 0.5
        });
        document.getElementById(`rotate-${imageId}`).value = 0;
        document.getElementById(`scale-${imageId}`).value = 1;
        setPickVisible(true);
    }
  
    return (
        <div className="image-select-container">
            <div className="image-select-canvas">
                <div id={`select-file-${imageId}`} className={`select-file ${pickVisible ? "show" : "hide"}`} onClick={() => {
                    document.getElementById(`new-image-${imageId}`).click();
                }}>
                    Select a Photo
                </div>
                <AvatarEditor
                    ref={setEditorRef}
                    image={image}
                    width={window.innerWidth}
                    height={window.innerWidth}
                    border={10}
                    color={[20, 20, 20, 0.6]} // RGBA
                    scale={scale}
                    rotate={rotate}
                    position={position}
                    borderRadius={radius}
                    onPositionChange={p => {
                        setPosition(p);
                    }}
                />
            </div>
            <input id={`new-image-${imageId}`} name={`new-image-${imageId}`} className="file-select" type="file" onChange={handleFileSelect} /><br />
            Zoom:
            <input
                id={`scale-${imageId}`}
                name="scale"
                type="range"
                onChange={handleScale}
                min={1}
                max="3"
                step="0.01"
                defaultValue={scale}
            /><br />
            Rotate:
            <input
                id={`rotate-${imageId}`}
                name="rotate"
                type="range"
                onChange={handleRotate}
                min={-45}
                max={45}
                step="1"
                defaultValue={rotate}
            />
            
            <div className="actions standard-buttons button-group">
            {
                (() => {
                    if(!pickVisible) {
                        return (
                            <button className="standard-button" onClick={() => {
                                saveImage();
                            }}><i className="fa-solid fa-upload" /> Upload</button>
                        );
                    }
                })()
            }
                <button className="standard-button" onClick={hideModal}><i className="fa-solid fa-xmark" /></button>
            </div>
            <div className={`close ${pickVisible ? "hide" : "show"}`} onClick={resetModal}>
                <i className="fa-solid fa-eraser" />
            </div>
        </div>
    )
}

export default ImageSet;