
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';
import moment from 'moment';

import config from './helper/config';
import idm from './helper/idm';
import cocktail from './helper/cocktail';

import ErrorBoundry from './ErrorBoundry';
import Header from './Header';
import RecipeSearch from './RecipeSearch';
import RecipeLoader from './RecipeLoader';
import OrderSearch from './OrderSearch';
import OrderLoader from './OrderLoader';
import RecipeForm from './RecipeForm';
import PatronInfo from './PatronInfo';
import MetricsHome from './MetricsHome';
import {setTokenIsValid} from './reducers/appSlice';
import {set as setPatrons} from './reducers/patronsSlice';
import {set as setRecipeCache} from './reducers/recipeCacheSlice';
import {set as setTags} from './reducers/tagsSlice';
import {set as setLocations} from './reducers/locationsSlice';

import styles from './css/App.css';
import fastyles from './fa/css/all.css';
import { counter } from '@fortawesome/fontawesome-svg-core';

const App = () => {
    const [tokenInitiated, setTokenInitiated] = useState(false);//useState(idm.isLoggedIn());
    //const [tokenIsValid, setTokenIsValid] = useState(idm.isLoggedIn());
    const [themeColor, setThemeColor] = useState(null);
    const tokenIsValid = useSelector((state) => state.app.tokenIsValid);
    const patrons = useSelector((state) => state.patrons.list);
    const recipeCache = useSelector((state) => state.recipeCache.list);
    const tags = useSelector((state) => state.tags.list);
    const locations = useSelector((state) => state.locations.list);
    const dispatch = useDispatch();

    const sessionChanged = (sessionValid) => {
        if(sessionValid) {
            //setTokenIsValid(true);
            dispatch(setTokenIsValid(true));
        } else {
            dispatch(setTokenIsValid(false));
            idm.clearAllTokens();
        }
    }

    useEffect(() => {
        idm.initiate({
            "isInitiated" : () => {
                if(!tokenInitiated) {
                    setTokenInitiated(true);
                }

                if(!tokenIsValid) {
                    dispatch(setTokenIsValid(true));
                }
            },
            "handleExpire" : sessionChanged
        });
    }, [tokenInitiated]);

    // initiate application cache
    useEffect(() => {
        // patrons
        if(idm.tokenHasRole("order_admin") && !patrons.length) {
            cocktail.getPatrons().then(output => {
                dispatch(setPatrons(output));
            }).catch(e => {
                console.error(e);
            });
        }

        // all recipes (limited fields)
        if(idm.tokenHasRole("recipe_admin") && recipeCache.length < 1) {
            cocktail.getRecipeCacheAll().then(output => {
                dispatch(setRecipeCache(output));
            }).catch(e => {
                console.error(e);
            });
        }

        if(idm.tokenHasRole("recipe_admin") && tags.length < 1) {
            cocktail.getTags().then(output => {
                dispatch(setTags(output));
            }).catch(e => {
                console.error(e);
            });
        }

        if(idm.tokenHasRole("order_viewer") && locations.length < 1) {
            cocktail.getLocations().then(output => {
                dispatch(setLocations(output));
            }).catch(e => {
                console.error(e);
            });
        }
    }, [tokenIsValid]);
    // end initiate application cache

    const setTokenIsValidDispatch = isValid => {
        dispatch(setTokenIsValid(isValid));
    }

    const NotFound = () => {
        return (
            <div className="not-found">
                O Noes!  The Route you attempted to reach could not be found...
            </div>
        );
    }

    /*
    const Welcome = () => {
        return (
            <div className="welcome">
                <div className="welcome-title">Welcome to {config.app.name}!</div>
                <div className="welcome-message">How would you like to browse our drinks?</div>
                <a onClick={() => {
                    window.location.href = "/recipes/favorites";
                }}>Favorites</a>
                <a onClick={() => {
                    window.location.href = "/recipes/seasonal";
                }}>Seasonal</a>
                <a onClick={() => {
                    window.location.href = "/recipes";
                }}>Everything</a>
                <a onClick={() => {
                    window.location.href = "/recipes/random";
                }}>Surprise Me!</a>
            </div>
        );
    }
    */

    const themeChanged = () => {
        if(document.getElementById("app-header")) {
            setThemeColor(window.getComputedStyle(document.getElementById("app-header")).backgroundColor.toString());
        }
    }

    return (
        <ErrorBoundry type="main">
            <BrowserRouter>
                <Header sessionChanged={sessionChanged} setTokenIsValid={setTokenIsValidDispatch} themeChanged={themeChanged} />
                <Routes>
                    {/*<Route path="/" element={<RecipeSearch searchType="general" />} />*/}
                    <Route path="/" element={<Welcome />} />
                    <Route path="/recipes" element={<RecipeSearch searchType="general" />} />
                    <Route path="/recipes/favorites" element={<RecipeSearch searchType="favorites" />} />
                    <Route path="/recipes/seasonal" element={<RecipeSearch searchType="seasonal" />} />
                    <Route path="/recipes/ingredients" element={<RecipeSearch searchType="ingredients" />} />
                    <Route path="/recipes/types/:type" element={<RecipeSearch searchType="types" />} />
                    <Route path="/recipes/spirits/:spirit" element={<RecipeSearch searchType="spirits" />} />
                    <Route path="/recipes/glasses/:glassType" element={<RecipeSearch searchType="glass-types" />} />
                    <Route path="/recipes/garnishes/:garnish" element={<RecipeSearch searchType="garnishes" />} />
                    <Route path="/recipes/tags/:tag" element={<RecipeSearch searchType="tags" />} />
                    <Route path="/recipes/create" element={<RecipeForm />} />
                    <Route path="/recipes/random" element={<RecipeLoader random={true} />} />
                    <Route path="/recipes/:recipeId" element={<RecipeLoader />} />
                    <Route path="/orders" element={<OrderSearch />} />
                    <Route path="/orders/patrons/:patron" element={<OrderSearch searchType="patron" />} />
                    <Route path="/orders/locations/:location" element={<OrderSearch searchType="location" />} />
                    <Route path="/orders/recipes/:recipe" element={<OrderSearch searchType="recipe" />} />
                    <Route path="/orders/spirits/:spirit" element={<OrderSearch searchType="spirits" />} />
                    <Route path="/orders/actual-spirits/:actualSpirit" element={<OrderSearch searchType="actual-spirit" />} />
                    <Route path="/orders/new" element={<OrderSearch searchType="new" />} />
                    <Route path="/orders/:orderId" element={<OrderLoader />} />
                    <Route path="/metrics" element={<MetricsHome themeColor={themeColor} />} />
                    <Route path="/metrics/popularity" element={<MetricsHome searchType="popularity" themeColor={themeColor} />} />
                    <Route path="/metrics/spirits" element={<MetricsHome searchType="spirits" themeColor={themeColor} />} />
                    <Route path="/metrics/trends" element={<MetricsHome searchType="trends" themeColor={themeColor} />} />
                    <Route path="/metrics/patrons/:patron" element={<MetricsHome searchType="patron" themeColor={themeColor} />} />
                    <Route path="/metrics/recipes/:recipeId" element={<MetricsHome searchType="recipe-id" themeColor={themeColor} />} />
                    <Route path="/metrics/recipes" element={<MetricsHome searchType="recipes" themeColor={themeColor} />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            <PatronInfo />
        </ErrorBoundry>
    );
}

//<RecipePreview recipeId="t4gi7X0BIqfO0_65H1lx" />
const Welcome = () => {
    const navigate = useNavigate();

    return (
        <div className="welcome">
            <PatronPopularOldestOrder />
            <PopularRecipes patron={cocktail.getPatronName()} />
            <HighestRatedRecipes patron={cocktail.getPatronName()} />
            <TimeOfYearPopular patron={cocktail.getPatronName()} range={30} />
            <PatronPopularByType patron={cocktail.getPatronName()} />
            <PatronPopularBySpirit patron={cocktail.getPatronName()} />
            <NewRecipes />
            <PopularRecipes />
            <HighestRatedRecipesBySpirits />
            <HighestRatedRecipes />
            <TimeOfYearPopular range={30} />
            <button className="recipe-search" onClick={() => {
                navigate("/recipes");
            }}>
                <i className="fa-solid fa-magnifying-glass" /> Search for More Drinks
            </button>
        </div>
    );
}


const NewRecipes = () => {
    const [recipes, setRecipes] = useState({});
    const [index, setIndex] = useState(0);

    useEffect(() => {
        (async () => {
            const recipes = await cocktail.getRecipesFeatured({
                "type" : "new",
                "size" : 5
            });

            setRecipes(recipes);
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className="carousel-container">
            <div className="carousel-header">
                <span className="section-title">Newly Added Drinks</span>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-new`}
                    recipeDoc={recipe}
                    typeText="Just Added!"
                    extraText={`Added on ${moment(recipe.added).format("dddd, MMMM Do, YYYY")}`}
                    detailText={`The five mostly recently added drinks are available here.  Only drinks with an picture will be listed.`}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={_index === index ? true : false}
                />
            })}
        </div>
    );
}


const PopularRecipes = ({ patron }) => {
    const [recipes, setRecipes] = useState([]);
    const [index, setIndex] = useState(0);
    const ordersHistory = patron ? 50 : 100;
    const recipesLimit = 5;

    useEffect(() => {
        (async () => {
            const recipes = await cocktail.getRecipesFeatured({
                "type" : patron ? `patrons/${patron}/popular` : "recent/popular",
                "size" : ordersHistory // number of recipes to check
            });

            // only use the top 3 recipes
            setRecipes(recipes.slice(0, recipesLimit));
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className={`carousel-container${patron ? "-patron" : ""}`}>
            <div className="carousel-header">
                <span className="section-title">{`Recently Popular ${patron ? " with " + cocktail.getPatronName("first") : ""}`}</span>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-popular`}
                    recipeDoc={recipe}
                    typeText="Popular!"
                    extraText={`${recipe.doc_count} orders in the last ${ordersHistory} drinks`}
                    detailText={(() => {
                        if(patron) {
                            return `Showing the ${recipes.length} most ordered drinks out of the last ${ordersHistory} submitted by ${cocktail.getPatronName()}.`;
                        } else {
                            return `Showing the ${recipes.length} most ordered drinks out of the last ${ordersHistory} submitted by any guest at ${config.app.default_establishment}.`;
                        }
                    })()}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={_index === index ? true : false}
                />
            })}
        </div>
    );
}


const HighestRatedRecipes = ({ patron }) => {
    const [recipes, setRecipes] = useState([]);
    const [index, setIndex] = useState(0);
    const ordersHistory = patron ? 50 : 100;
    const recipeCarouselSize = 5;
    const minOrders = patron ? 1 : 2;

    useEffect(() => {
        (async () => {
            const recipes = await cocktail.getRecipesFeatured({
                "type" : patron ? `patrons/${patron}/high-rating` : "recent/high-rating",
                "size" : ordersHistory, // number of recipes to check
                "minOrders" : minOrders
            });

            // only use the top 3 recipes
            setRecipes(recipes.slice(0, recipeCarouselSize));
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className={`carousel-container${patron ? "-patron" : ""}`}>
            <div className="carousel-header">
                <span className="section-title">{`Recently High Rated ${patron ? " by " + cocktail.getPatronName("first") : ""}`}</span>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-rating`}
                    recipeDoc={recipe}
                    typeText="High Rating!"
                    extraText={`Rated ${Number(Number(recipe.recent_avg_rating / 2).toPrecision(3))} out of 5 in the last ${ordersHistory} drinks`}
                    detailText={(() => {
                        if(patron) {
                            return `Showing the ${recipes.length} highest rated drinks out of the last ${ordersHistory} orders submitted by ${cocktail.getPatronName()}.`;
                        } else {
                            return `Showing the ${recipes.length} highest rated drinks out of the last ${ordersHistory} orders submitted by any guest at ${config.app.default_establishment}. (Recent drink ratings require a minimum of ${minOrders} orders)`;
                        }
                    })()}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={index === _index}
                />
            })}
        </div>
    );
}


const HighestRatedRecipesBySpirits = () => {
    const [recipes, setRecipes] = useState([]);
    const [index, setIndex] = useState(0);
    const spiritsLimit = 5;

    useEffect(() => {
        (async () => {
            const recipes = await cocktail.getRecipesFeatured({
                "type" : "spirits/high-rating",
                "size" : spiritsLimit // number of spirits to get a recipe for
            });

            setRecipes(recipes);
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className="carousel-container">
            <div className="carousel-header">
                <span className="section-title">Highest Rated Drinks by Spirit</span>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-high-rating-spirit`}
                    recipeDoc={recipe}
                    typeText={recipe.primary_spirit}
                    extraText={`Rated ${Number(Number(recipe.average_rating / 2).toPrecision(3))} out of 5 - ${recipe.primary_spirit} drink`}
                    detailText={`Showing the ${spiritsLimit} most ordered spirits based on the drink's primary alcoholic ingredient and each spirit's highest rated drink.`}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={index === _index}
                />
            })}
        </div>
    );
}


const PatronPopularOldestOrder = () => {
    const [recipes, setRecipes] = useState([]);
    const [index, setIndex] = useState(0);
    const recipesLimit = 5;

    useEffect(() => {
        (async () => {
            const recipes = await cocktail.getRecipesFeatured({
                "type" : `patrons/${cocktail.getPatronName()}/popular/old`,
                "size" :  recipesLimit // number of spirits to get a recipe for
            });

            setRecipes(recipes.sort((a, b) => {
                return moment(a.patron_last_ordered_on).unix() - moment(b.patron_last_ordered_on).unix();
            }));
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className={`carousel-container-patron`}>
            <div className="carousel-header">
                <div className="section-title">Old Favorites for {cocktail.getPatronName("first")}</div>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-patron`}
                    recipeDoc={recipe}
                    typeText={`For ${cocktail.getPatronName("first")}`}
                    extraText={`${recipe.patron_doc_count} orders in the last ${moment(recipe.patron_last_ordered_on).fromNow(true)}`}
                    detailText={`These drinks are the ${recipesLimit} most commonly ordered by ${cocktail.getPatronName("first")}. They are sorted by the last time a drink was ordered, oldest to newest.`}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={index === _index}
                />
            })}
        </div>
    );
}


const TimeOfYearPopular = ({ patron, dayOfYear=null, range=null }) => {
    const [recipes, setRecipes] = useState([]);
    const [index, setIndex] = useState(0);
    const recipesLimit = 5;
    range = range ?? 30;

    useEffect(() => {
        (async () => {
            const recipes = await cocktail.getRecipesFeatured({
                "type" : patron ? `patrons/${cocktail.getPatronName()}/time-of-year/popular` : `time-of-year/popular`,
                "dayOfYear" : dayOfYear, // the day of year to query orders relative to (defaults to today)
                "range" : range // number of days +/- the dayOfYear (defaults to 30)
            });

            setRecipes(recipes.sort((a, b) => {
                return moment(a.patron_last_ordered_on).unix() - moment(b.patron_last_ordered_on).unix();
            }).slice(0, recipes.length > recipesLimit ? recipesLimit : recipes.length));
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className={`carousel-container${patron ? "-patron" : ""}`}>
            <div className="carousel-header">
                <div className="section-title">{`Seasonally Popular${patron ? " with " + cocktail.getPatronName("first") : ""}`}</div>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-season-popular-${patron}`}
                    recipeDoc={recipe}
                    typeText={`Seasonal`}
                    extraText={`${recipe.range_order_count} drinks ordered this time of year`}
                    detailText={(() => {
                        if(patron) {
                            return `Showing the ${recipesLimit} most ordered drinks by ${cocktail.getPatronName()} for this time of year, which is defined as ${moment().subtract(30, 'days').format("MMMM Do")} through ${moment().add(30, 'days').format("MMMM Do")}.`;
                        } else {
                            return `Showing the ${recipesLimit} most ordered drinks for this time of year, which is defined as ${moment().subtract(30, 'days').format("MMMM Do")} through ${moment().add(30, 'days').format("MMMM Do")}.`;
                        }
                    })()}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={index === _index}
                />
            })}
        </div>
    );
}


const PatronPopularBySpirit = ({ patron }) => {
    const [recipes, setRecipes] = useState([]);
    const [spirit, setSpirit] = useState(null);
    const [index, setIndex] = useState(0);
    const recipesLimit = 5;

    useEffect(() => {
        (async () => {
            const spirits = await cocktail.getSpiritsByPatron(patron, {
                "exclude" : {
                    "type" : "Spirit Only"
                }
            });
            
            if(spirits.length > 0) {
                setSpirit(spirits[0].spirit);
                const recipes = await cocktail.getRecipesFeatured({
                    "type" : `patrons/${cocktail.getPatronName()}/spirits/${spirits[0].spirit}/popular`,
                    "limit" : recipesLimit,
                    "size" : 0
                });

                setRecipes(recipes.sort((a, b) => {
                    return moment(b.patron_order_count).unix() - moment(a.patron_order_count).unix();
                }));
            }
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className={`carousel-container${patron ? "-patron" : ""}`}>
            <div className="carousel-header">
                <div className="section-title">{`${spirit} Drinks Ordered by ${cocktail.getPatronName("first")}`}</div>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-patron-${patron}-${spirit}`}
                    recipeDoc={recipe}
                    typeText={spirit}
                    extraText={`${recipe.patron_order_count} ${spirit} drink${recipe.patron_order_count > 1 ? "s" : ""} ordered by ${cocktail.getPatronName("first")}`}
                    detailText={`${cocktail.getPatronName("first")} has ordered drinks with ${spirit} more often than any other liquor.  These are the most ordered ${spirit} drinks by ${cocktail.getPatronName("first")}.  (Spirit Only drinks are ommited from this list)`}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={index === _index}
                />
            })}
        </div>
    );
}


const PatronPopularByType = ({ patron }) => {
    const [recipes, setRecipes] = useState([]);
    const [type, setType] = useState(null);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        (async () => {
            const types = await cocktail.getTypesByPatron(patron);
            
            if(types.length > 0) {
                setType(types[0].type);
                const recipes = await cocktail.getRecipesFeatured({
                    "type" : `patrons/${cocktail.getPatronName()}/types/${types[0].type}/popular`,
                    "limit" : 5,
                    "size" : 0
                });

                setRecipes(recipes.sort((a, b) => {
                    return moment(b.patron_order_count).unix() - moment(a.patron_order_count).unix();
                }));
            }
        })();
    }, []);

    if(!recipes.length) {
        return <></>
    }

    const next = () => {
        if(index + 1 >= recipes.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const previous = () => {
        if(index == 0) {
            setIndex(recipes.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <div className={`carousel-container${patron ? "-patron" : ""}`}>
            <div className="carousel-header">
                <div className="section-title">{`${type} Orders by ${cocktail.getPatronName("first")}`}</div>
            </div>
            {recipes.map((recipe, _index) => {
                return <RecipePreview
                    key={`recipe-preview-${recipe.id}-patron-${patron}-type-${type}`}
                    recipeDoc={recipe}
                    typeText={type}
                    extraText={`${recipe.patron_order_count} ${recipe.type} drink${recipe.patron_order_count > 1 ? "s" : ""} ordered by ${cocktail.getPatronName("first")}`}
                    detailText={`Based on order history, we see that ${cocktail.getPatronName("first")} enjoys ${type} drinks.  These are the most ordered ${type} drinks by ${cocktail.getPatronName("first")}.`}
                    carousel={true}
                    next={next}
                    previous={previous}
                    visible={index === _index}
                />
            })}
        </div>
    );
}


const RecipePreview = ({ recipeDoc = {}, recipeId, typeText, extraText, detailText, carousel, next, previous, visible }) => {
    // recipeId is only really used to test
    const [recipe, setRecipe] = useState(recipeDoc);
    const [iconData, setIconData] = useState(null);
    const [ingredients, setIngredients] = useState([]);
    const [detailVisible, setDetailVisible] = useState(false);
    const navigate = useNavigate();
    const isVisible = visible === false ? false : true;

    useEffect(() => {
        if(!recipeId) {
            return;
        }

        (async () => {
            setRecipe(await cocktail.getRecipe(recipeId));
        })();
    }, [recipeId]);

    useEffect(() => {
        if(!recipe.id) {
            return;
        }

        (async () => {
            setIngredients(await cocktail.getRecipeIngredients(recipe.id));

            if(recipe.icon) {
                setIconData(await cocktail.getImage(recipe.id, "icon", "recipe", 450));
            }
        })();
    }, [recipe]);


    const Ingredients = () => {
        if(!ingredients) {
            return <></>
        }

        return (
            <div className="ingredients">
                {ingredients.map(ingredient => {
                    return <span
                        key={`ingredient-${recipe.id}-${ingredient.id}`}
                        className="ingredient"
                    >{ingredient.ingredient}</span>
                })}
            </div>
        )
    }

    const Next = () => {
        if(carousel && next) {
            return <button onClick={(event) => {
                next();

                event.stopPropagation()
            }}><i className="fa-solid fa-chevron-right" /></button>
        }

        return <></>
    }

    const Previous = () => {
        if(carousel && previous) {
            return <button onClick={(event) => {
                previous();

                event.stopPropagation()
            }}><i className="fa-solid fa-chevron-left" /></button>
        }

        return <></>
    }

    if(!recipe.id) {
        return <></>
    }

    return (
        <div
            className={`recipe-preview ${!isVisible ? "hide" : ""}`}
            style={{
                "backgroundImage" : `linear-gradient(to right, var(--color-surface-100-opacity50) 0 100%), url('data:image/png;base64,${iconData}')`
            }}
            onClick={() => {
                navigate(`/recipes/${recipe.slug}?origin=featured`);
            }}
        >
            <span className="title">{recipe.name}</span><br />
            <Ingredients />
            <div className="preview-actions">
                <div className="button-group">
                    <Previous />
                    <button className="primary" onClick={() => {
                        navigate(`/recipes/${recipe.slug}?origin=featured`);
                    }}>
                        <i className="fa-solid fa-whiskey-glass" />
                    </button>
                    <Next />
                </div>
                <div className="preview-actions-text">
                    <span>{extraText}</span>
                </div>
            </div>
            <div className={`highlight ${detailVisible ? "open" : ""}`} onClick={(e) => {
                if(detailText) {
                    if(detailVisible) {
                        setDetailVisible(false);
                    } else {
                        setDetailVisible(true);
                    }
                }

                e.stopPropagation();
            }}>
                {typeText}
            </div>
            <div className={`highlight-detail ${detailVisible ? "show" : "hide"}`} onClick={(e) => {
                e.stopPropagation();
            }}>
                {detailText}
            </div>
        </div>
    );
}

export default App;
