import React, {useEffect, useState, useRef} from 'react';
import {Routes, Route, Outlet, Link} from 'react-router-dom';

import idm from './helper/idm';
import ws from './helper/ws';
import app from './helper/app';
import cocktail from './helper/cocktail';
import TypeaheadField from './TypeaheadField';

import styles from './css/RecipeForm.css'
import fastyles from './fa/css/all.css';

const RecipeForm = ({ recipe = {}, hideModal, setRecipe }) => {
    const [recipeCache, setRecipeCache] = useState([]);
    const [tagCache, setTagCache] = useState([]);
    const [glassTypeCache, setGlassTypeCache] = useState([]);
    const [spiritCache, setSpiritCache] = useState([]);
    const [typeCache, setTypeCache] = useState([]);
    const [garnishCache, setGarnishCache] = useState([]);
    const [recipeSpirits, setRecipeSpirits] = useState(recipe.spirits ?? []);
    const [recipeGarnishes, setRecipeGarnishes] = useState(recipe.garnish ?? []);
    const [recipeTags, setRecipeTags] = useState(recipe.tags ?? []);
    const [recipeVariations, setRecipeVariations] = useState(recipe.variations ?? []);
    const recipeDraft = useRef({});
    const self = useRef(null);
    const recipeFields = [
        "name",
        "description",
        "primary_spirit",
        "spirits",
        "ingredients",
        "steps",
        "garnish",
        "type",
        "glass_type",
        "slug",
        "page_number",
        "variations",
        "tags",
        "notes"
    ]

    // remove the error classes from each field when the form first loads
    useEffect(() => {
        recipeFields.forEach(field => {
            document.getElementById(`recipe-${field}`).classList.remove("error");
        });
    });

    // load recipe and tag cache whenever the recipe changes (for typeahead and tagging)
    useEffect(() => {
        // reset the field values to their default values
        // this prevents a field that should be blank from being populated
        // with the last open document's value
        recipeFields.forEach(field => {
            let el = document.getElementById(`recipe-${field}`);

            el.value = el.defaultValue;
        });

        // retrieve and populate typeahead cache
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/cache",
            "params" : {
                "size" : 1000
            }
        }).then(output => {
            setRecipeCache(output.data.response);
        }).catch(e => {
            console.error(e);
        });

        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/tags"
        }).then(output => {
            setTagCache(output.data.response);
        }).catch(e => {
            console.error(e);
        });

        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/glass-types"
        }).then(output => {
            setGlassTypeCache(output.data.response.map(glassType => {
                return glassType.glass_type;
            }));
        }).catch(e => {
            console.error(e);
        });

        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/all-spirits"
        }).then(output => {
            setSpiritCache(output.data.response.map(spirit => {
                return spirit.spirit;
            }));
        }).catch(e => {
            console.error(e);
        });

        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/types"
        }).then(output => {
            setTypeCache(output.data.response.map(type => {
                return type.type;
            }));
        }).catch(e => {
            console.error(e);
        });

        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/garnishes"
        }).then(output => {
            setGarnishCache(output.data.response.map(garnish => {
                return garnish.garnish;
            }));
        }).catch(e => {
            console.error(e);
        });

        setRecipeSpirits(recipe.spirits ?? []);
        setRecipeGarnishes(recipe.garnish ?? []);
        setRecipeVariations(recipe.variations ?? []);
        setRecipeTags(recipe.tags ?? []);

        // initiate autoresize of textarea fields
        ["ingredients", "steps"].forEach(field => {
            document.getElementById(`recipe-${field}`).dispatchEvent(new Event("input", { bubbles: true }));
        });

        // set the recipe draft object (for tracking changes)
        //recipeFields.forEach(_field => {
        //    recipeDraft.current[_field] = recipe[_field];
        //});
        recipeDraft.current = {...recipe};

        // scroll to the top (if dialog was previously closed and scrolled)
        self.current.parentNode.scrollTop = 0;
    }, [recipe]);

    const validateVariations = () => {
        const variations = document.getElementById(`recipe-variations`).value.split(/,\s+?/);
        const unmatched = [];

        // filter out the empty string element if the field is empty
        variations.filter(variation => variation !== "").forEach(variation => {
            if(!recipeCache.find(recipe => recipe.name === variation)) {
                unmatched.push(variation);
            }
        });

        return unmatched.length > 0 ? unmatched : false;
    }

    const saveRecipe = () => {
        let recipeDoc = { };
        
        recipeFields.forEach(field => {
            if(field == "spirits") {
                recipeDoc[field] = recipeSpirits;
            } else if(field == "garnish") {
                recipeDoc[field] = recipeGarnishes;
            } else if(field == "variations") {
                recipeDoc[field] = recipeVariations;
            } else if(field == "tags") {
                recipeDoc[field] = recipeTags;
            } else if(field == "ingredients" || field == "steps") {
                recipeDoc[field] = document.getElementById(`recipe-${field}`).value.split(/\r?\n/).filter(val => val !== "")
            } else {
                recipeDoc[field] = document.getElementById(`recipe-${field}`).value || null;
            }
        });

        const unmatchedVariations = validateVariations();
        if(unmatchedVariations) {
            app.showAlertModal({
                "type" : "error",
                "message" : "Variations must exist as recipes to be entered here.  The following variations could not be located:<br /><br /><b>&nbsp;&nbsp;- " + unmatchedVariations.join("<br />&nbsp;&nbsp;- ") + "</b><br /><br />Please correct these variations before saving this recipe."
            });
            return;
        } else if(/[,\.\/\\\|\^]/gi.test(recipeDoc.name)) {
            app.showAlertModal({
                "type" : "error",
                "message" : "The recipe name contains unsupported characters, such as commas, periods, or slashes.  Please remove these before proceeding."
            });
            return;
        }

        if(recipe.id) {
            cocktail.recipeUpdate({
                "recipe" : recipeDoc,
                "id" : recipe.id,
                "callback" : (updatedRecipe) => {
                    recipeSaved(updatedRecipe, "updated");
                },
                "error" : (error = {}) => {
                    if(error.missingFields) {
                        error.missingFields.forEach((field) => {
                            document.getElementById(`recipe-${field}`).classList.add("error");
                        });
                    } else {
                        alert(error.error);
                    }
                }
            });
        } else {
            cocktail.recipeCreate({
                "recipe" : recipeDoc,
                "callback" : (newRecipe) => {
                    recipeSaved(newRecipe, "added");
                },
                "error" : (error = {}) => {
                    if(error.missingFields) {
                        error.missingFields.forEach((field) => {
                            document.getElementById(`recipe-${field}`).classList.add("error");
                        });
                    } else {
                        alert(error.error);
                    }
                }
            });
        }
    }

    const recipeSaved = (recipeDoc) => {
        hideModal();
        setRecipe(recipeDoc);
    }


    const clearError = (event) => {
        if(event.target.value) {
            event.target.classList.remove("error");
        }
    }

    const actionButtons = () => {
        return (
            <div className="recipe-form-actions button-group">
                <button className="primary" onClick={() => {
                    saveRecipe();
                }}><i className="fa-solid fa-floppy-disk" /> Save</button>
                <button onClick={() => {
                    hideModal();
                }}><i className="fa-solid fa-xmark" /></button>
            </div>
        );
    }

    const sanitizeFilterText = text => {
        return text.replace(/[^a-z0-9\s\-_(),]/gi, "");
    }

    const getFilterText = text => {
        return text.split(/,\s+?/).pop();
    }

    const filterRecipes = text => {
        const filterText = getFilterText(sanitizeFilterText(text));
        const regex1 = new RegExp(sanitizeFilterText(text), "gi");
        const regex2 = new RegExp(`${filterText.split(/\s+/g).join("|")}`, "gi");
        return recipeCache.filter(document => 
                (
                    regex1.test(document.name) ||
                    regex2.test(document.name) ||
                    regex1.test(document.description) ||
                    regex2.test(document.description) ||
                    regex1.test(document.primary_spirit) ||
                    regex2.test(document.primary_spirit)
                )
                && document.name !== recipe.name // removes the current recipe (cannot be a variant of itself)
                && !recipeVariations.includes(document.name)
            ).map(recipe => {
                return recipe.name;
            }
        ).sort();
    }

    const filterTags = text => {
        const filterText = getFilterText(text);
        const regex1 = new RegExp(filterText, "gi");
        return tagCache.filter(
            tag => regex1.test(tag.tag) && !recipeTags.includes(tag.tag)
        ).map(tag => {
            return {
                "key" : tag.tag,
                "display" : `${tag.tag} (${tag.count})`
            }
        }).sort();
    }

    const filterGlassTypes = text => {
        const regex1 = new RegExp(text, "gi");
        return glassTypeCache.filter(glassType => regex1.test(glassType)).sort();
    }

    const filterSpirits = text => {
        const regex1 = new RegExp(sanitizeFilterText(text), "gi");
        return spiritCache.filter(
            spirit => regex1.test(spirit)
        ).sort();
    }

    const filterAllSpirits = text => {
        const regex1 = new RegExp(sanitizeFilterText(text), "gi");

        // return results that match the input text and are not already used as a spirit
        return spiritCache.filter(
            spirit => regex1.test(spirit) && !recipeSpirits.includes(spirit)
        ).sort();
    }

    const filterTypes = text => {
        const regex1 = new RegExp(sanitizeFilterText(text), "gi");
        return typeCache.filter(type => regex1.test(type)).sort();
    }

    const filterGarnishes = text => {
        const filterText = text.split(/\r?\n/).pop();
        const regex1 = new RegExp(filterText, "gi");
        return garnishCache.filter(
            garnish => regex1.test(garnish)
            && garnish !== ""
            && !recipeGarnishes.includes(garnish)
        ).sort();
    }

    const setField = (item, fieldId) => {
        const field = document.getElementById(fieldId);
        const fragments = field.value.split(/,\s+?/);
        fragments.pop();

        // set the input field value here
        fragments.push(item);
        field.value = fragments.join(", ") + ", ";
        document.getElementById(fieldId).focus();
    }

    const setTextareaField = (item, fieldId) => {
        const field = document.getElementById(fieldId);
        const fragments = field.value.split(/\r?\n/);
        fragments.pop();

        // set the input field value here
        fragments.push(item);
        field.value = fragments.join("\n") + "\n";
        document.getElementById(fieldId).focus();
    }

    const autoResize = event => {
        //event.target.style.height = "";
        if(event.target.scrollHeight > 50) {
            event.target.style.height = event.target.scrollHeight + "px";
        } else {
            event.target.style.height = "50px";
        }
    }
    
    return (
        <div className="recipe-form" ref={self}>
            <div className="inner">
                <div className="details">
                    <div className="recipe-field">
                        <span>Recipe Name:</span>
                        <input id="recipe-name" className="recipe-name recipe-field" defaultValue={recipe.name} />
                    </div>
                    <div className="recipe-field">
                        <span>Description:</span>
                        <textarea id="recipe-description" className="recipe-input" onChange={clearError} defaultValue={recipe.description} />
                    </div>
                    <div className="recipe-field">
                        <span>Primary Spirit:</span>
                        <TypeaheadField
                            key="recipe-primary_spirit-key"
                            fieldId="recipe-primary_spirit"
                            containerClass="field-container-single"
                            defaultValue={recipe.primary_spirit}
                            filterMatches={filterSpirits}
                            onChange={clearError}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Spirits:</span>
                        <TypeaheadField
                            key={`recipe-spirits`}
                            fieldId={`recipe-spirits`}
                            containerClass="field-container-single"
                            multiValues={recipeSpirits}
                            multiSelect={true}
                            multiSetValues={(values) => {
                                setRecipeSpirits(values);
                            }}
                            filterMatches={filterAllSpirits}
                            selectAction={(tagValue) => {
                                setRecipeSpirits([...recipeSpirits, tagValue]);
                            }}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Ingredients:</span>
                        <textarea
                            id="recipe-ingredients"
                            onChange={clearError}
                            defaultValue={recipe.ingredients ? recipe.ingredients.join("\n") : null}
                            onInput={autoResize}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Steps:</span>
                        <textarea
                            id="recipe-steps"
                            onChange={clearError}
                            defaultValue={recipe.steps ? recipe.steps.join("\n") : null}
                            onInput={autoResize}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Garnish:</span>
                        <TypeaheadField
                            key={`recipe-garnish`}
                            fieldId={`recipe-garnish`}
                            containerClass=""
                            multiValues={recipeGarnishes}
                            multiSelect={true}
                            multiSetValues={(values) => {
                                setRecipeGarnishes(values);
                            }}
                            filterMatches={filterGarnishes}
                            selectAction={(tagValue) => {
                                setRecipeGarnishes([...recipeGarnishes, tagValue]);
                            }}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Drink Type:</span>
                        <TypeaheadField
                            key="recipe-type-key"
                            fieldId="recipe-type"
                            containerClass="field-container field-container-single"
                            defaultValue={recipe.type}
                            filterMatches={filterTypes}
                            onChange={clearError}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Glass Type:</span>
                        <TypeaheadField
                            key="recipe-glass_type-key"
                            fieldId="recipe-glass_type"
                            containerClass="field-container field-container-single"
                            defaultValue={recipe.glass_type}
                            filterMatches={filterGlassTypes}
                            onChange={clearError}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Slug:</span>
                        <input id="recipe-slug" className="recipe-input" onChange={clearError} defaultValue={recipe.slug} />
                    </div>
                    <div className="recipe-field">
                        <span>Page Number:</span>
                        <input id="recipe-page_number" className="recipe-input" onChange={clearError} defaultValue={recipe.page_number} />
                    </div>
                    <div className="recipe-field">
                        <span>Variations:</span>
                        <TypeaheadField
                            key={`recipe-variations`}
                            fieldId={`recipe-variations`}
                            containerClass=""
                            multiValues={recipeVariations}
                            multiSelect={true}
                            multiSetValues={(values) => {
                                setRecipeVariations(values);
                            }}
                            filterMatches={filterRecipes}
                            selectAction={(tagValue) => {
                                setRecipeVariations([...recipeVariations, tagValue]);
                            }}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Tags:</span>
                        <TypeaheadField
                            key={`recipe-tags`}
                            fieldId={`recipe-tags`}
                            containerClass=""
                            multiValues={recipeTags}
                            multiSelect={true}
                            multiSetValues={(values) => {
                                setRecipeTags(values);
                            }}
                            filterMatches={filterTags}
                            selectAction={(tagValue) => {
                                setRecipeTags([...recipeTags, tagValue]);
                            }}
                            showAllButton={true}
                        />
                    </div>
                    <div className="recipe-field">
                        <span>Notes:</span>
                        <textarea id="recipe-notes" onChange={clearError} defaultValue={recipe.notes} />
                    </div>
                    { actionButtons() }
                </div>
            </div>
        </div>
    );
}
export default RecipeForm;