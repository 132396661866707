import React, {useState, useEffect, useReducer} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';

import Recipe from './Recipe';
import Modal from './Modal';

import cocktail from './helper/cocktail';

const RecipeLoader = ({ random }) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [recipe, setRecipe] = useState({});
    const [editVisible, setEditVisible] = useState(false);
    const [error, setError] = useState(null);
    const recipeId = params.recipeId;
    const r = searchParams.get("r");

    useEffect(() => {
        // don't run if the recipe has already been loaded
        // or if no recipe ID has been provided
        if(recipe.id == recipeId || !recipeId) {
            return;
        }

        cocktail.getRecipe(recipeId).then(recipeDoc => {
            setRecipe(recipeDoc);
            setError(null);
        }).catch(e => {
            setError("Recipe not available");
        });
    }, [recipeId]);

    useEffect(() => {
        if(!random) {
            return;
        }

        cocktail.getRecipeRandom().then(recipeDoc => {
            setRecipe(recipeDoc.data.response);
            setError(null);
        }).catch(e => {
            setError("Could not load random recipe");
        });

        return;
    }, [r])

    const hideEditModal = () => {
        setEditVisible(false);
    }

    const handleRecipeSaved = recipeDoc => {
        setRecipe(recipeDoc);
    }

    const showRecipeEdit = ({ hideRecipeModal }) => {
        setEditVisible(true);

        if(hideRecipeModal) {
            setEditVisible(false);
        }
    }

    const renderRecipe = () => {

        if(recipe.id) {
            return (
                <>
                    <Recipe recipeDef={recipe} showRecipeEdit={showRecipeEdit} displayType={random ? "random" : "single"} />
                    <Modal displayComponent="recipe-add" modalId={"recipe-add"} visible={editVisible} hideModal={hideEditModal} recipe={recipe} setRecipe={handleRecipeSaved} />
                </>
            );
        } else if(error) {
            return <div className="recipe-error-msg">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        } else {
            return <></>
        }
    }

    return renderRecipe();
}

export default RecipeLoader;