import React, {useState, useEffect, useRef} from 'react';

import config from './helper/config';

import styles from './css/TypeaheadField.css';

const useOutsideBlur = (ref, hideTypeahead) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideTypeahead();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const TypeaheadField = ({
    InputType = "input",
    fieldId,
    containerClass,
    inputClass = "",
    className = "",
    buttonClass = "",
    filterMatches,
    defaultValue = "",
    multiValues = [],
    placeholder = "",
    selectAction,
    multiSelect = false,
    multiAllowNew = true,
    multiSetValues = () => {},
    onBlur = () => {},
    onClick = () => {},
    onChange = () => {},
    showAllButton = false,
    showLastButton = false,
    lastValue = () => {}
}) => {
    const [inputText, setInputText] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showAll, setShowAll] = useState(false);
    const componentRef = useRef(null);
    const inputRef = useRef(null);
    const backspaceCount = useRef(0);
    const buttonCount = (() => {
        if(showAllButton && showLastButton) {
            return 2;
        } else if(showAllButton || showLastButton) {
            return 1;
        } else {
            return 0;
        }
    })();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const hideTypeahead = () => {
        setSelectedIndex(-1);
        setInputText("");
        setShowAll(false);
        backspaceCount.current = 0;
    }

    const removeLastMultiValue = () => {
        multiSetValues(multiValues.slice(0, -1));
    }

    const setFocus = () => {
        document.getElementById(fieldId).focus();
    }

    const handleTextChange = event => {
        setInputText(event.target.value);
    }

    const selectItem = () => {
        let value = "";

        // selectedIndex being set means enter was pressed on a typeahead value
        if(selectedIndex > -1) {
            value = document.getElementById(`ta-list-${fieldId}`).getElementsByTagName("li")[selectedIndex].getAttribute('data-value');
        } else {
            value = inputRef.current.value;
        }

        if(selectAction) {
            selectAction(value, fieldId);
        } else {
            multiSetValues([...multiValues, value]);
        }
    }

    const TypeaheadList = () => {
        if(!filterMatches) {
            return null;
        }

        if(inputText.length < 1 && !showAll) {
            return null;
        }

        const matches = filterMatches(inputText, showAll);

        if(matches.length < 1) {
            return null;
        }

        return (
            <ul key={`ta-list-${fieldId}`} id={`ta-list-${fieldId}`} className={`ta-list-${buttonCount}`}>
                {matches.map((item, i) => {
                    // item formats accept are single value, or
                    // {"key" : value, "display" : display_value}
                    const key = item.key || item;
                    const display = item.display || item;

                    if(!key && !display) {
                        return "";
                    }

                    return (
                        <li
                            key={`${fieldId}-${key}`}
                            data-value={key}
                            className={`${selectedIndex == i ? "selected" : ""}`}
                            onClick={(event) => {
                                if(selectAction) {
                                    selectAction(key, fieldId);
                                } else {
                                    document.getElementById(fieldId).value = key;
                                }

                                // clear the input field if multiple selections are allowed
                                if(multiSelect) {
                                    setInputText("");
                                    document.getElementById(fieldId).value = "";
                                }

                                hideTypeahead();
                                setFocus();
                            }}>
                            {display}
                        </li>
                    );
                })}
            </ul>
        );
    }

    const ShowAddButton = () => {
        if(multiSelect && multiAllowNew) {
            return (
                <button
                    className={`helper ${buttonClass} ${multiSelect ? "tags-button" : ""} helper`}
                    onClick={() => {
                        selectItem();
                    }}
                >
                    <i className="fa-solid fa-tag" />
                </button>
            );
        } else {
            return <></>
        }
    }

    const ShowAllButton = () => {
        if(showAllButton) {
            return (
                <button
                    className={`helper ${buttonClass} ${multiSelect ? "tags-button" : ""} helper-last`}
                    onClick={() => {
                        if(!showAll) {
                            setShowAll(true);
                        } else {
                            setShowAll(false);
                        }
                    }}
                >
                    <i className={`fa fa-chevron-${showAll ? "up" : "down"}`} />
                </button>
            );
        } else {
            return <></>
        }
    }

    const ShowLastButton = () => {
        if(showLastButton) {
            return (
                <button
                    className={`helper ${buttonClass} ${!showAllButton ? "helper-last" : ""}`}
                    onClick={() => {
                        lastValue(placeholder).then(value => {
                            document.getElementById(fieldId).value = value;
                        }).catch(e => {
                            console.error(e);
                        })
                    }}
                >
                    <i
                        className={`fa fa-backward-step`}
                    />
                </button>
            );
        } else {
            return <></>
        }
    }

    const TypeaheadInputClass = () => {
        if(showAllButton && showLastButton) {
            return "button-helper-2";
        } else if(showAllButton || showLastButton) {
            return "button-helper-1";
        } else {
            return "button-helper-0";
        }
    }

    // setup the off click typeahead hider
    useOutsideBlur(componentRef, hideTypeahead);

    const keyDownEvent = event => {
        if(!inputText && multiSelect) {
            if(event.code === "Backspace") {
                backspaceCount.current += 1;
            } else {
                backspaceCount.current = 0;
            }

            if(backspaceCount.current >= 2) {
                backspaceCount.current = 0;
                removeLastMultiValue();
            }
        }

        if(event.code === "Enter") {
            // only execute select item if in multiselect mode and enter is
            // pressed on a selected item or new item adds are allowed
            if(multiSelect && (multiAllowNew || selectedIndex > -1)) {
                selectItem();
            }

            hideTypeahead();
            return;
        } else if(event.code === "ArrowDown") {
            const list = document.getElementById(`ta-list-${fieldId}`);
            const listElements = list ? list.getElementsByTagName("li") : [];

            setSelectedIndex(selectedIndex + 1 >= listElements.length ? listElements.length - 1 : selectedIndex + 1);
            event.preventDefault();
        } else if(event.code === "ArrowUp") {
            setSelectedIndex(selectedIndex - 1 > -2 ? selectedIndex - 1 : -1);
            event.preventDefault();
        } else if(event.code === "Tab") {
            // if a selection is made with up/down arrows and tab is used to make a selection
            if(selectedIndex > -1) {
                const _value = document.getElementById(`ta-list-${fieldId}`).getElementsByTagName("li")[selectedIndex].getAttribute('data-value');
                if(selectAction) {
                    selectAction(_value, fieldId);
                } else if(multiSelect) {
                    multiSetValues([...multiValues, _value]);
                } else {
                    event.target.value = _value;
                }

                event.preventDefault();
            }

            hideTypeahead();
        }
    }

    // setup the off click typeahead hider
    useOutsideBlur(componentRef, hideTypeahead);

    if(multiSelect) {
        // sanity check, multiValues should be an array
        if(multiValues && !Array.isArray(multiValues)) {
            multiValues = [multiValues];
        } else if(!multiValues) {
            multiValues = [];
        }

        return (
            <div className="tags-container" ref={componentRef}>
                {
                    multiValues.map(value => {
                        return <div
                            key={`${fieldId}-${value}`}
                            className="tag"
                            onClick={() => {
                                multiSetValues(multiValues.filter(_value => _value !== value));
                            }}
                        >
                            {value}
                        </div>
                    })
                }
                <div className={`ta-container tags-typeahead ${containerClass ?? ""}`}>
                    <InputType
                        ref={inputRef}
                        type="text"
                        className={`${inputClass || className} ${multiSelect ? "tags-field" : ""} button-helper ${TypeaheadInputClass()}`}
                        id={fieldId}
                        placeholder={placeholder}
                        defaultValue={inputText}
                        onClick={event => {
                            // any extra click logic can be included here
                            onClick(event);
                        }}
                        onBlur={event => {
                            // any extra blur logic can be included here
                            onBlur(event);
                        }}
                        onChange={event => {
                            handleTextChange(event);
        
                            // any extra change logic can be included here
                            onChange(event);
                        }}
                        onKeyDown={keyDownEvent}
                    />
                    <ShowAddButton />
                    <ShowLastButton />
                    <ShowAllButton />
                    <TypeaheadList />
                </div>
            </div>
        );
    } else {
        return (
            <div className={`ta-container ${containerClass ?? ""}`} ref={componentRef}>
                <InputType type="text"
                    ref={inputRef}
                    className={`${inputClass || className} button-helper ${TypeaheadInputClass()}`}
                    id={fieldId}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onClick={event => {
                        // any extra click logic can be included here
                        onClick(event);
                    }}
                    onBlur={event => {
                        // any extra blur logic can be included here
                        onBlur(event);
                    }}
                    onChange={event => {
                        handleTextChange(event);

                        // any extra change logic can be included here
                        onChange(event);
                    }}
                    onKeyDown={keyDownEvent}
                />
                <ShowLastButton />
                <ShowAllButton />
                <TypeaheadList />
            </div>
        );
    }
}

export default TypeaheadField;