import React, {useState, useEffect, useReducer, useRef} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import moment from 'moment';

import RecipeActions from './RecipeActions';
import Modal from './Modal';

import ws from './helper/ws';
import idm from './helper/idm';
import cocktail from './helper/cocktail';

import styles from './css/Recipe.css';
import fastyles from './fa/css/all.css';

const reducer = (state, action) => {
    return {...state, ...action};
}

const Recipe = React.memo(({ recipeDef = {}, hideRecipeModal, showRecipeEdit, refreshSearch, displayType, loadRecipe }) => {
    //const [recipe, setRecipe] = useState(recipeDef);
    const [order, setOrder] = useState({});
    const [iconData, setIconData] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [imageModalIsVisible, setImageModalIsVisible] = useState(false);
    const [imageViewModalIsVisible, setImageViewModalIsVisible] = useState(false);
    const initialState = {
        "recipe" : recipeDef,
        "order" : {},
        "extendedVisible" : displayType === "list" ? false : true,
        "orderModalIsVisible" : false,
        "imageModalIsVisible" : false,
        "imageViewModalIsVisible" : false
    }
    const [state, setState] = useReducer(reducer, initialState);
    const recipeRef = useRef(null);
    const navigate = useNavigate();

    //console.log(`<Recipe>${Date.now()}</Recipe>`);

    useEffect(() => {
        setState({
            "recipe" : recipeDef
        });

        getRecipeIcon();
    }, [recipeDef]);


    const getRecipeIcon = async () => {
        if(state.recipe.icon) {
            setIconData(await cocktail.getImage(state.recipe.id, "icon", "recipe"));
        }
    }


    const setRecipeOrder = (orderDoc) => {
        // hide order modal if deleted
        if(!orderDoc.id) {
            hideOrderModal();
        }

        setState({
            "orderModalIsVisible" : false,
            "order" : orderDoc
        });

        if(refreshSearch) {
            refreshSearch();
        }
    }


    const showImageModal = () => {
        //setImageModalIsVisible(true);
        setState({
            "imageModalIsVisible" : true
        });
    }


    const hideImageModal = () => {
        //setImageModalIsVisible(false);
        setState({
            "imageModalIsVisible" : false
        });

        if(refreshSearch) {
            refreshSearch();
        }

        if(loadRecipe) {
            loadRecipe(true);
        }
    }


    const showImageViewModal = () => {
        //setImageViewModalIsVisible(true);
        setState({
            "imageViewModalIsVisible" : true
        });
    }

    const hideImageViewModal = () => {
        //setImageViewModalIsVisible(false);
        setState({
            "imageViewModalIsVisible" : false
        });
    }

    const showOrderModal = () => {
        setModalIsVisible(true);
    }

    const hideOrderModal = () => {
        setModalIsVisible(false);
    }

    const showEditImage = () => {
        if(state.imageViewModalIsVisible) {
            hideImageViewModal();
        }

        showImageModal();
    }

    const orderCreated = (orderId) => {
        cocktail.getOrder(
            orderId
        ).then(orderDoc => {
            let orders = localStorage.getItem("orderIds");
            let existingOrders = [];

            try {
                existingOrders = JSON.parse(orders);
                if(!existingOrders) {
                    existingOrders = [];
                }
            } catch(e) {
                existingOrders = [];
            }

            existingOrders.push(orderId);

            localStorage.setItem("orderIds", JSON.stringify(existingOrders));

            setOrder(orderDoc);
            //showOrderModal();
        }).catch(e => {
            console.log(e);
        });
    }

    const Icon = () => {
        const IconImg = () => {
            if(iconData) {
                return <img
                    key={state.recipe.image.filename}
                    onClick={showImageViewModal}
                    className="uploaded"
                    src={`data:image/png;base64,${iconData}`}
                />
            } else {
                return <img />
            }
        }

        if(iconData || state.recipe.icon) {
            return (
                <div className="drink-image-container">
                    <div className="drink-image">
                        <IconImg />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="drink-image-container">
                    <div className="drink-image">
                        <img className="generic" src="/images/Americano-icon.png" />
                        {
                            (() => {
                                if(idm.tokenHasRole("recipe_admin")) {
                                    return <div className="image-edit" onClick={ showImageModal }><i className="fa-solid fa-upload" /></div>
                                }
                            })()
                        }
                    </div>
                    
                </div>
            );
        }
    }

    const Ingredients = () => {
        return (
            <>
                <b>Ingredients</b><br />
                {
                    state.recipe.ingredients.map(ingredient => {
                        let _matchedSpirit = null;
                        let _text = null;

                        state.recipe.spirits.forEach(spirit => {
                            let regex = new RegExp(spirit, "gi");

                            if(regex.test(ingredient)) {
                                _matchedSpirit = spirit;
                            }
                        });

                        if(_matchedSpirit) {
                            const ingredientRegEx = new RegExp(`${_matchedSpirit}`, "gi");
                            const ingredientPieces = ingredient.split(ingredientRegEx);

                            return (
                                <div key={ingredient + "-" + state.recipe.id} className="ingredient">
                                    {ingredientPieces[0]}
                                    <a onClick={() => {
                                        navigate(`/recipes/spirits/${_matchedSpirit}`);
                                    }}>{_matchedSpirit}</a>
                                    {ingredientPieces[1]}
                                </div>
                            )
                        } else {
                            return <div key={ingredient + "-" + state.recipe.id} className="ingredient">{ingredient}</div>
                        }
                    })
                }
            </>
        );
    }

    const Garnish = () => {
        if(!state.recipe.garnish) {
            return "";
        } else if(state.recipe.garnish.length < 1) {
            return "";
        }

        const _garnish = Array.isArray(state.recipe.garnish) ? state.recipe.garnish : state.recipe.garnish.split(/,\s?/);

        return (
            <>
                <hr />
                <b>Garnish{_garnish.length > 1 ? "es" : ""}</b><br />
                {
                    _garnish.map(garnish => {
                        return (
                            <div key={"garnish-" + garnish + "-" + state.recipe.id} className="ingredient">
                                <a onClick={() => {
                                    navigate(`/recipes/garnishes/${garnish}`);
                                }}>{garnish}</a>
                            </div>
                        );
                    })
                }
            </>
        );
    }

    const Variations = () => {
        if(!state.recipe.variations) {
            return "";
        } else if(!state.recipe.variations.length) {
            return "";
        }

        // some fields may still be stored as strings, not lists
        const variations = state.recipe.variations.map ? state.recipe.variations : state.recipe.variations.split(/,\s+?/);

        return (
            <>
                <hr />
                <b>Variations</b>
                <div className="links-container">
                    {
                        variations.map((variation, i) => {
                            return (
                                <div key={`variation-${state.recipe.id}-${variation}`} className="link-container">
                                    <a onClick={event => {
                                        navigate(`/recipes/${variation}`);
                                    }}>{variation}</a>{i + 1 != variations.length ? ", " : ""}
                                </div>
                            );
                        })
                    }
                </div>
            </>
        );
    }

    const Tags = () => {
        if(!state.recipe.tags) {
            return "";
        } else if(!state.recipe.tags.length) {
            return "";
        }

        // some fields may still be stored as strings, not lists
        const tags = state.recipe.tags.map ? state.recipe.tags : state.recipe.tags.split(/,\s+?/);

        return (
            <>
                <hr />
                <b>Tags</b>
                <div className="links-container">
                    {
                        tags.map((tag, i) => {
                            return (
                                <div key={`tags-${state.recipe.id}-${tag}`} className="link-container">
                                    <a onClick={event => {
                                        navigate(`/recipes/tags/${tag}`);
                                    }}>{tag}</a>{i + 1 != tags.length ? ", " : ""}
                                </div>
                            );
                        })
                    }
                </div>
            </>
        );
    }

    const Notes = () => {
        if(!state.recipe.notes) {
            return "";
        }

        return (
            <>
                <hr />
                <b>Notes</b><br />
                <div className="notes">
                    {state.recipe.notes}
                </div>
            </>
        )
    }

    const LastOrdered = () => {
        if(!state.recipe.last_ordered) {
            return "";
        }

        const OrderedOn = () => {
            if(moment().diff(moment(state.recipe.last_ordered), 'days') > 15) {
                return moment(state.recipe.last_ordered).format("dddd, MMMM Do, YYYY")
            } else {
                return moment(state.recipe.last_ordered).fromNow()
            }
        }

        return (
            <>
                <hr />
                <b>Last Ordered</b><br />
                <div className="notes">
                    { OrderedOn() }
                </div>
            </>
        )
    }

    const RatingView = () => {
        let iteration = 0;

        if(state.recipe.average_rating) {
            let avRatingRemainder = Number(Math.ceil(((state.recipe.average_rating / 2) % 1) * 10) * 10);
            const halfIcon = avRatingRemainder ? <i key={state.recipe.id + "-100"} className={`fa fa-martini-glass rating-icon rating-icon-partial-${avRatingRemainder}`}></i> : '';
            return (<div className="recipe-rating">
                {[...Array(Math.floor(state.recipe.average_rating / 2))].map(element => {
                    iteration++;
                    return <i key={state.recipe.id + "-" + iteration} className="fa fa-martini-glass rating-icon rating-icon-full"></i>
                })}
                {halfIcon}
                {[...Array(Math.floor((10 - state.recipe.average_rating) / 2))].map((i) => {
                    iteration++;
                    return <i key={state.recipe.id + "-" + iteration} className="fa fa-martini-glass rating-icon rating-icon-empty"></i>
                })}
            <span className="rating-num">({state.recipe.order_count})</span></div>);
        }

        return "";
    }

    const MoreLessToggle = () => {
        if(displayType !== "list") {
            return "";
        }

        return (
            <>
                <div key={`recipe-extended-button-${state.recipe.id}-more`} className={`recipe-extended-button ${state.extendedVisible ? "hide" : "show"}`}>
                    &lt;&lt; <a className="show-more-less" onClick={() => {
                        setState({
                            "extendedVisible" : true
                        });
                    }}>Show More</a> &gt;&gt;
                </div>
                <div key={`recipe-extended-button-${state.recipe.id}-less`} className={`recipe-extended-button ${state.extendedVisible ? "show" : "hide"}`}>
                    &gt;&gt; <a className="show-more-less" onClick={() => {
                        setState({
                            "extendedVisible" : false
                        });
                    }}>Show Less</a> &lt;&lt;
                </div>
            </>
        )
    }

    const showRecipeEditIntercept = (recipeToEdit) => {
        if(!showRecipeEdit) {
            console.log("No showRecipeEdit routine, exiting");
            return;
        }

        showRecipeEdit({
            "recipeToEdit" : recipeToEdit,
            "hideRecipeModal" : hideRecipeModal
        });
    }

    const handleDelete = () => {
        cocktail.recipeDelete({
            "id" : state.recipe.id,
            "callback" : (output) => {
                if(refreshSearch) {
                    refreshSearch();
                }

                if(hideRecipeModal) {
                    hideRecipeModal();
                }
            },
            "error" : (error) => {
                console.log(error);
            }
        })
    }

    if(!state.recipe.id) {
        return <div style={{padding: "10px"}}>Recipe definition not provided<br /><button onClick={hideRecipeModal}>Close</button></div>;
    }

    return (
        <div id={`recipe-${state.recipe.id}`} className="recipe" ref={recipeRef}>
            <div className="inner">
                <Icon />
                <div className="title">{state.recipe.name}</div>
                <div className="spirits">
                    {
                        state.recipe.spirits.map(spirit => {
                            return <div key={spirit} className={spirit == state.recipe.primary_spirit ? 'primary-spirit' : 'spirit'}>{spirit}</div>
                        })
                    }
                    <div className="spirit">&nbsp;</div>
                </div>
                <div className="details">
                    <div className="sub-title">{state.recipe.description}</div>
                    <hr />
                    <Ingredients />
                    <div key={`recipe-extended-${state.recipe.id}`} className={`recipe-extended ${state.extendedVisible ? "show" : "hide"}`}>
                        <hr />
                        <b>Cocktail Preparation</b><br />
                        {
                            state.recipe.steps.map(step => {
                                return <div key={step + "-" + state.recipe.id} className="step">{step}</div>
                            })
                        }
                        <Garnish />
                        <hr />
                        Drink Type:<div className="link-container">
                            <a onClick={() => {
                                navigate(`/recipes/types/${state.recipe.type}`);
                            }}>{state.recipe.type}</a>
                        </div>&nbsp;
                        Glass Type:<div className="link-container">
                            <a onClick={() => {
                                navigate(`/recipes/glasses/${state.recipe.glass_type}`);
                            }}>{state.recipe.glass_type}</a>
                        </div>
                        <Variations />
                        <Tags />
                        <Notes />
                        <LastOrdered />
                    </div>
                    <div className="split-container more-less-rating">
                        <div>
                            <MoreLessToggle />
                        </div>
                        <div>
                            <RatingView />
                        </div>
                    </div>
                </div>
                <RecipeActions
                    key={"recipe-order-" + state.recipe.id}
                    recipe={state.recipe}
                    orderCreated={orderCreated}
                    showRecipeEdit={showRecipeEditIntercept}
                    handleDelete={handleDelete}
                    hideRecipeModal={hideRecipeModal}
                    displayType={displayType}
                    recipeRef={recipeRef}
                />
            </div>
            <Modal displayComponent="order" modalId={"order-modal-" + state.recipe.id} order={state.order} setOrder={setRecipeOrder} visible={state.orderModalIsVisible} hideModal={hideOrderModal} />
            <Modal displayComponent="image-set" modalId={"image-set-" + state.recipe.id} recipe={state.recipe} visible={state.imageModalIsVisible} hideModal={hideImageModal} />
            <Modal displayComponent="image-view" modalId={"image-view-" + state.recipe.id} recipe={state.recipe} visible={state.imageViewModalIsVisible} hideModal={hideImageViewModal} editImage={showEditImage} />
        </div>
    );
});

export default Recipe;