'use strict';

import moment from 'moment';
import axios from 'axios';

import ws from './ws';
import idm from './idm';
import config from './config';

var __EXPORTS = {};

const getPatronName = (type = null) => {
    try {
        if(type === "first") {
            return localStorage.getItem("patronName").split(" ")[0];
        } else if(type === "last") {
            return localStorage.getItem("patronName").split(" ")[1];
        }

        return localStorage.getItem("patronName");
    } catch(e) {
        console.error(e);
        return null;
    }
}
__EXPORTS['getPatronName'] = getPatronName;

const setPatronName = (name) => {
    localStorage.setItem("patronName", name);
}
__EXPORTS['setPatronName'] = setPatronName;

const getEstablishmentName = () => {
    if(idm.tokenHasRole("location_write")) {
        return localStorage.getItem("establishmentName") || idm.getTokenAttribute("location");
    } else {
        return idm.getTokenAttribute("location");
    }
}
__EXPORTS['getEstablishmentName'] = getEstablishmentName;

const setEstablishmentName = (name) => {
    localStorage.setItem("establishmentName", name);
}
__EXPORTS['setEstablishmentName'] = setEstablishmentName;


const recipeCreate = ({ recipe, callback, error }) => {
    const __error = (msg) => {
        return {
            "error" : msg
        };
    }

    // ensure all required fields are present
    const missingFields = __checkRequired({
        "fieldsToRequire" : [
            "name",
            "description",
            "primary_spirit",
            "spirits",
            "ingredients",
            "steps",
            "type",
            "glass_type",
            "slug"
        ],
        "document" : recipe
    });

    if(missingFields.length > 0) {
        error({
            "missingFields" : missingFields
        });
        return;
    }

    // ensure the recipe name and slug have not been used
    ws.apiRequest({
        "method" : "POST",
        "path" : "/recipes",
        "data" : {
            "query" : [
                {
                    "name" : {
                        "value" : recipe.name,
                        "op" : "eq"
                    }
                },
                {
                    "slug" : {
                        "value" : recipe.slug,
                        "op" : "like" // using "like" because slug is a keyword only field mapping
                    }
                }
            ]
        },
        "callback" : (output) => {
            // existing recipe matched, return error
            if(output.hits > 0) {
                error({
                    "error" : "One or more recipes with the same name and/or slug already exist -- Please try another name or slug"
                });
                return;
            }

            ws.apiRequest({
                "method" : "PUT",
                "path" : "/recipes/create",
                "data" : {
                    "recipe" : recipe
                },
                "callback" : (output) => {
                    if(output.error) {
                        error(output);
                        return;
                    }

                    // get the full document of the newly created recipe
                    // by not specifying a callback method, we use the native
                    // axios ".then" method
                    getRecipe(output.response.id).then((recipeDoc) => {
                        callback(recipeDoc);
                    }).catch(e => {
                        throw new Error(e);
                    });
                }
            });
        }
    });

    return;
}
__EXPORTS['recipeCreate'] = recipeCreate;


const recipeUpdate = ({ recipe, id, callback, error }) => {
    if(!id) {
        console.error("No ID provided to cocktail.recipeUpdate method");
        error({
            "error" : "An error was encountered updating this receipe"
        });
        return;
    }

    // ensure all required fields are present
    const missingFields = __checkRequired({
        "fieldsToRequire" : [
            "name",
            "description",
            "primary_spirit",
            "spirits",
            "ingredients",
            "steps",
            "type",
            "glass_type",
            "slug"
        ],
        "document" : recipe
    });

    if(missingFields.length > 0) {
        error({
            "missingFields" : missingFields
        });
        return;
    }

    let searchQuery = {
        "query" : [
            {
                "name" : {
                    "value" : recipe.name,
                    "op" : "eq"
                }
            },
            {
                "slug" : {
                    "value" : recipe.slug,
                    "op" : "like" // using "like" because slug is a keyword only field mapping
                }
            }
        ]
    };

    // ensure the recipe name and slug have not been used
    ws.apiRequest({
        "method" : "POST",
        "path" : "/recipes",
        "data" : searchQuery,
        "callback" : (output) => {
            // existing recipe matched, return error
            if(output.hits > 0) {
                let conflictFound = output.hits > 1;

                // check for all documents that do not match the current one
                output.response.forEach(document => {
                    if(document.id != id) {
                        conflictFound = true;
                    }
                });

                if(conflictFound) {
                    error({
                        "error" : "One or more recipes with the same name and/or slug already exist -- Please try another name or slug"
                    });
                    return;
                }
            }

            ws.apiRequest({
                "method" : "POST",
                "path" : `/recipes/${id}`,
                "data" : {
                    "recipe" : recipe
                },
                "callback" : (output) => {
                    if(output.error) {
                        error(output);
                        return;
                    }

                    // get the full document of the newly created recipe
                    // by not specifying a callback method, we use the native
                    // axios ".then" method
                    getRecipe(output.response.id).then((recipeDoc) => {
                        //console.log(response.data);
                        callback(recipeDoc);
                    });
                }
            });
        }
    });

    return;
}
__EXPORTS['recipeUpdate'] = recipeUpdate;


const recipeTagsUpdate = ({ id, tags }) => {
    return new Promise((resolve, reject) => {
        try {
            ws.apiRequest({
                "method" : "POST",
                "path" : `/recipes/${id}/tags`,
                "data" : {
                    "recipe" : {
                        "tags" : tags
                    }
                },
                "callback" : (output) => {
                    if(output.error) {
                        reject(output);
                        return;
                    }

                    // get the full document of the newly created recipe
                    // by not specifying a callback method, we use the native
                    // axios ".then" method
                    getRecipe(output.response.id).then((recipeDoc) => {
                        //console.log(response.data);
                        resolve(recipeDoc);
                    });
                }
            });
        } catch(e) {
            reject(e);
        }
    });
}
__EXPORTS['recipeTagsUpdate'] = recipeTagsUpdate;


const recipeVariationsUpdate = ({ id, variations }) => {
    return new Promise((resolve, reject) => {
        try {
            ws.apiRequest({
                "method" : "POST",
                "path" : `/recipes/${id}/variations`,
                "data" : {
                    "recipe" : {
                        "variations" : variations
                    }
                },
                "callback" : (output) => {
                    if(output.error) {
                        reject(output);
                        return;
                    }

                    // get the full document of the newly created recipe
                    // by not specifying a callback method, we use the native
                    // axios ".then" method
                    getRecipe(output.response.id).then((recipeDoc) => {
                        resolve(recipeDoc);
                    });
                }
            });
        } catch(e) {
            reject(e);
        }
    });
}
__EXPORTS['recipeVariationsUpdate'] = recipeVariationsUpdate;


const recipeDelete = ({ id, callback, error }) => {
    if(!id) {
        console.error("No ID provided to cocktail.recipeDelete method");
        error({
            "error" : "An error was encountered deleting this receipe"
        });
        return;
    }

    // check for any associated cocktail orders
    // do not delete the recipe if orders for it have been created
    ws.apiRequest({
        "method" : "GET",
        "path" : `/orders/recipes/${id}`
    }).then((response) => {
        if(response.data.hits > 0) {
            error({
                "error" : "Cocktail orders have been placed for this recipe and it cannot be deleted"
            })
        } else {
            ws.apiRequest({
                "method" : "DELETE",
                "path" : `/recipes/${id}`
            }).then(output => {
                callback(output.data);
            }).catch(error => {
                error(error);
            });
        }
    }).catch((wsError) => {
        console.log(wsError);
    });
}
__EXPORTS['recipeDelete'] = recipeDelete;


const getRecipe = async (recipeId) => {
    // recipeId can be either the document id or the slug
    /*return ws.apiRequest({
        "method" : "GET",
        "path" : `/recipes/${recipeId}`
    });*/
    try {
        const respObj = await ws.call({
            "method" : "GET",
            "path" : `/recipes/${recipeId}`
        });
        return respObj.response;
    } catch(e) {
        throw new Error(e);
    }
}
__EXPORTS['getRecipe'] = getRecipe;


const getRecipeRandom = (recipeId) => {
    // recipeId can be either the document id or the slug
    return ws.apiRequest({
        "method" : "GET",
        "path" : `/recipes/random`
    });
}
__EXPORTS['getRecipeRandom'] = getRecipeRandom;


const getRecipeIngredients = async (recipeId) => {
    // recipeId can be either the document id or the slug
    try {
        const respObj = await ws.call({
            "method" : "GET",
            "path" : `/recipes/${recipeId}/ingredients`
        });
        return respObj.response;
    } catch(e) {
        throw new Error(e);
    }
}
__EXPORTS['getRecipeIngredients'] = getRecipeIngredients;


const getRecipesFeatured = async (options={}) => {
    try {
        let path = `/recipes/featured/${options.type}?size=${options.size ?? ""}`;

        if(options.minOrders) {
            path += `&min_orders=${options.minOrders}`;
        }

        if(options.dayOfYear) {
            path += `&day_of_year=${options.dayOfYear}`;
        }

        if(options.range) {
            path += `&range=${options.range}`;
        }

        const respObj = await ws.call({
            "method" : "GET",
            "path" : path
        });

        return respObj.response;
    } catch(e) {
        throw new Error(e);
    }
}
__EXPORTS['getRecipesFeatured'] = getRecipesFeatured;


const getImage = async (id, imageType="icon", docType="recipe", iconSize) => {
    // this logic assume an image/icon has been set for the recipe
    // only use if you are sure the recipe has an image/icon
    try {
        const headers = {};

        if(idm.isLoggedIn()) {
            headers['Authorization'] = `Bearer ${idm.getAccessToken()}`;
        }

        const response = await axios.get(ws.getEndpoint(`/${docType}s/${id}/${imageType}?cachebust=${moment().unix()}&icon_size=${iconSize}`), {
            "responseType" : "arraybuffer",
            "headers" : headers
        });

        const base64Data = btoa(
            new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
        );

        return base64Data;
    } catch(e) {
        console.error(e);
        return "";
    }
}
__EXPORTS['getImage'] = getImage;


const uploadImage = async ({ originalFileField, iconData, originalFilename, fileId, documentId, documentType, rotate, scale, position }) => {
    //console.log(originalFileField);
    //return;
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        const fileInfo =  originalFileField;
        const fileExtension = fileInfo ? fileInfo.name.match(/\.[0-9a-z]+$/i)[0] : originalFilename.match(/\.[0-9a-z]+$/i)[0];

        if(!/jpe?g/i.test(fileExtension) && !/png/i.test(fileExtension)) {
            alert("File type selected is not supported.  Must be either JPG or PNG format.");
            return;
        }

        // nothing to save
        if(!iconData && !fileInfo) {
            return;
        }

        const filename = originalFilename ? originalFilename.replace(/\.[a-z0-9]+/i, "") : `${fileId}-${moment().format("YYYYMMDDHHmmss")}`;
        iconData = iconData.replace(/^data\:image\/png;base64,/, "");

        const uploadIcon = () => {
            ws.apiRequest({
                "method" : "PUT",
                "path" : `/${documentType}s/${documentId}/icon`,
                "data" : {
                    "file_data" : iconData,
                    "filename" : `${filename}.png`,
                    "type" : "image/png",
                    "scale" : scale,
                    "position" : position,
                    "rotate" : rotate
                }
            }).then(() => {
                resolve();
            }).catch(e => {
                reject(e);
            });
        }

        if(fileInfo) {
            formData.append(
                `${documentType}-image`,
                fileInfo,
                `${filename}${fileExtension}`
            );

            // write the file handle field directly to the web services
            // we bypass the apiRequest method since it cannot handle non-JSON requests
            let headers = {};

            if(idm.isLoggedIn()) {
                headers['Authorization'] = `Bearer ${idm.getAccessToken()}`;
            }

            axios.request({
                "url" : ws.getEndpoint(`/${documentType}s/${documentId}/image`),
                "method" : "PUT",
                "data" : formData,
                "headers" : headers
            }).then(() => {
                uploadIcon();
            }).catch(e => {
                reject(e);
            });
        } else {
            uploadIcon();
        }
    });
}
__EXPORTS['uploadImage'] = uploadImage;


const orderCreate = ({ recipeId, orderedBy, orderedAt = config.app.default_establishment, details }) => {
    return new Promise((resolve, reject) => {
        let orderPayload = {
            "order" : {
                "recipe_id" : recipeId,
                "ordered_by" : orderedBy,
                "ordered_at" : orderedAt
            }
        }

        if(details) {
            orderPayload.order['details'] = details;
        }

        ws.apiRequest({
            "method" : "PUT",
            "path" : "/orders/create",
            "data" : orderPayload,
            "callback" : (output) => {
                resolve(output.response.id);
            }
        });
    });
}
__EXPORTS['orderCreate'] = orderCreate;


const orderUpdate = ({ order, id, orderedBy, orderedAt, orderedOn, details, notes, comments, rating, actualSpirits, complete, callback=() => {}, error=() => {} }) => {
    /*
    accepted:
    actualSpirits = {
        "Bourbon" : "Evan Williams",
        "Tequila" : "Casamigos"
    }
    transformed to:
    [
        {
            "type" : "Bourbon",
            "used" : "Evan Williams"
        }, {
            "type" : "Tequila",
            "used" : "Casamigos"
        }
    ]
    */
    if(!id) {
        console.error("No ID provided to cocktail.orderUpdate method");
        error({
            "error" : "An error was encountered updating this order"
        });
        return;
    }

    let orderDoc = {
        "order" : {
            "recipe_id" : order.recipe_id
        }
    }

    if(actualSpirits) {
        let actual_spirits = order.spirits.map(spirit_type => {
            return {
                "type" : spirit_type,
                "used" : actualSpirits[spirit_type]
            }
        });

        orderDoc.order['actual_spirits'] = actual_spirits;
    }

    if(details) {
        orderDoc.order['details'] = details ? details : null;
    }

    if(notes) {
        orderDoc.order['notes'] = notes ? notes : null;
    }

    if(comments) {
        orderDoc.order['comments'] = comments ? comments : null;
    }

    if(orderedBy) {
        orderDoc.order['ordered_by'] = orderedBy;
    }

    if(orderedAt) {
        orderDoc.order['ordered_at'] = orderedAt;
    }

    if(orderedOn) {
        orderDoc.order['ordered_on'] = moment(orderedOn).utc().format("YYYY-MM-DDTHH:mm:ssZZ");
    }

    if(rating) {
        orderDoc.order['rating'] = rating;
    }

    // this much logic around complete orders is silly
    // complete was getting set to false in some instance when it was not provided
    if(complete) {
        orderDoc.order['complete'] = true;
    } else if(`${complete}` === "false") {
        orderDoc.order['complete'] = false;
    } else if(order.complete) {
        orderDoc.order['complete'] = true;
    } else if(`${complete}` === "false") {
        orderDoc.order['complete'] = false;
    }

    ws.apiRequest({
        "method" : "POST",
        "path" : `/orders/${order.id}`,
        "data" : orderDoc,
        "callback" : (output) => {
            ws.apiRequest({
                "method" : "GET",
                "path" : `/orders/${order.id}`,
                "callback" : (orderOutput) => {
                    callback(orderOutput.response);
                }
            });
        }
    });
}
__EXPORTS['orderUpdate'] = orderUpdate;


const getOrdersByRecipe = recipeId => {
    return new Promise((resolve, reject) => {
        try {
            ws.apiRequest({
                "method" : "GET",
                "path" : `/orders/recipe/${recipeId}?sort=ordered_on&direction=desc`,
                "callback" : (output) => {
                    resolve(output);
                }
            });
        } catch(e) {
            reject(e);
        }
    });
}
__EXPORTS['getOrdersByRecipe'] = getOrdersByRecipe;


const getOrdersBySpirit = spirit => {
    return new Promise((resolve, reject) => {
        try {
            ws.apiRequest({
                "method" : "GET",
                "path" : `/orders/spirits/${spirit}?sort=ordered_on&direction=desc`,
                "callback" : (output) => {
                    resolve(output);
                }
            });
        } catch(e) {
            reject(e);
        }
    });
}
__EXPORTS['getOrdersBySpirit'] = getOrdersBySpirit;


const getOrder = orderId => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : `/orders/${orderId}`,
            "callback" : (output) => {
                resolve(output.response);
            }
        });
    });
}
__EXPORTS['getOrder'] = getOrder;


const getSpirits = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/spirits"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getSpirits'] = getSpirits;


const getSpiritsByPatron = (patron, options={}) => {
    return new Promise((resolve, reject) => {
        let path = `/orders/patrons/${patron}/spirits`;

        if(options.exclude) {
            if(options.exclude.type) {
                path += `?exclude_type=${options.exclude.type}`;
            }
        }

        ws.apiRequest({
            "method" : "GET",
            "path" : path
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getSpiritsByPatron'] = getSpiritsByPatron;


const getTypesByPatron = (patron) => {
    return new Promise((resolve, reject) => {
        let path = `/orders/patrons/${patron}/types`;

        ws.apiRequest({
            "method" : "GET",
            "path" : path
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getTypesByPatron'] = getTypesByPatron;


const getTypes = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/types"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getTypes'] = getTypes;


const getTags = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/tags"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getTags'] = getTags;


const getGlassTypes = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/glass-types"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getGlassTypes'] = getGlassTypes;


const getIngredients = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/ingredients"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getIngredients'] = getIngredients;


const getPatrons = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/orders/patrons"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getPatrons'] = getPatrons;


const getLocations = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/orders/locations"
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getLocations'] = getLocations;


const getRecipeCacheAll = () => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : "/recipes/cache",
            "params" : {
                "size" : 1000
            }
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}
__EXPORTS['getRecipeCacheAll'] = getRecipeCacheAll;


const canEditOrder = orderId => {
    let canEdit = false;

    try {
        const orders = JSON.parse(localStorage.getItem("orderIds"));
        canEdit = orders.includes(orderId);
    } catch(e) {
        canEdit = false;
    }

    if(idm.tokenHasRole("order_admin")) {
        canEdit = true;
    }

    return canEdit;
}
__EXPORTS['canEditOrder'] = canEditOrder;


const getCurrentSeasonTag = () => {
    let tag = "";
    let now = moment().dayOfYear();

    // analyze the current day of the year (e.g. March 17 = 76)
    // use this to determine the best tag to identify seasonal
    // drinks by (dates do not adjust for leap years)
    if(now < 74 || now >= 361) {
        // Winter (December 28th - March 14th)
        tag = "Winter";
    } else if(now >= 74 && now < 145) {
        // Spring (March 15th - May 24th)
        tag = "Spring";
    } else if(now >= 145 && now < 258) {
        // Summer (May 25th - September 14th)
        tag = "Summer";
    } else if(now >= 258 && now < 335) {
        // Fall (September 15th - November 30th)
        tag = "Fall";
    } else if(now >= 335 && now < 361) {
        // Holidays (December 1st - December 27th)
        tag = "Holiday";
    }

    return tag;
}
__EXPORTS['getCurrentSeasonTag'] = getCurrentSeasonTag;


const __checkRequired = ({ fieldsToRequire = [], document = {} }) => {
    return fieldsToRequire.filter(field => !document[field]);
}


export default __EXPORTS;