import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import idm from './helper/idm';
import app from './helper/app';
import config from './helper/config';
import Login from './Login';
import NavModal from './NavModal';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';

import styles from './css/Header.css';
import fastyles from './fa/css/all.css';
import { theme } from 'highcharts';

const useOutsideBlur = (ref, hideMenu) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const Header = ({ setTokenIsValid, sessionChanged, themeChanged=()=>{} }) => {
    const [screen, setScreen] = useState(null);
    const [loginVisible, setLoginVisible] = useState(false);
    const [themeNumber, setThemeNumber] = useState(localStorage.getItem("themeNumber") ?? config.app.default_theme);
    const [isLoggedIn, setIsLoggedIn] = useState(idm.isLoggedIn());
    const [recipeNavVisible, setRecipeNavVisible] = useState(false);
    const [spiritsNavVisible, setSpiritsNavVisible] = useState(false);
    const [typesNavVisible, setTypesNavVisible] = useState(false);
    const [ingredientsNavVisible, setIngredientsNavVisible] = useState(false);
    const [tagsNavVisible, setTagsNavVisible] = useState(false);
    const [orderNavVisible, setOrderNavVisible] = useState(false);
    const [patronNavVisible, setPatronNavVisible] = useState(false);
    const [locationNavVisible, setLocationNavVisible] = useState(false);
    const [orderSpiritsNavVisible, setOrderSpiritsNavVisible] = useState(false);
    const [userMenuNavVisible, setUserMenuNavVisible] = useState(false);
    const [shareNavVisible, setShareNavVisible] = useState(false);
    const [passwordNavVisible, setPasswordNavVisible] = useState(false);
    const [metricsNavVisible, setMetricsNavVisible] = useState(false);
    const [metricsPatronsNavVisible, setMetricsPatronsNavVisible] = useState(false);
    const [aboutNavVisible, setAboutNavVisible] = useState(false);
    const [secondaryMenuVisible, setSecondaryMenuVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef();

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        const themeRegex = /^theme\-\d+/g;
        let currentThemeNumber = null;

        [...body.classList].forEach(className => {
            let classMatch = className.match(themeRegex);

            if(classMatch) {
                currentThemeNumber = classMatch[0].split("-")[1];
            }
        });

        [...body.classList].forEach(className => {
            if(className.match(themeRegex)) {
                body.classList.remove(className);
            }
        });

        if(themeNumber != currentThemeNumber) {
            localStorage.setItem("themeNumber", themeNumber);
            body.classList.add(`theme-${themeNumber}`);
            themeChanged(themeNumber);
        } else if(localStorage.getItem("themeNumber")) {
            body.classList.add(`theme-${localStorage.getItem("themeNumber")}`);
            themeChanged(localStorage.getItem("themeNumber"));
        }
    }, [themeNumber]);

    useEffect(() => {
        if(location.pathname === "/") {
            setScreen("home");
        } else if(location.pathname.startsWith("/recipes")) {
            setScreen("recipes");
        } else if(location.pathname.startsWith("/orders")) {
            setScreen("orders");
        } else if(location.pathname.startsWith("/metrics")) {
            setScreen("metrics");
        }
    }, [location.pathname]);

    const showLogin = () => {
        setLoginVisible(true);
    }

    const loginSuccessful = () => {
        setIsLoggedIn(true);
        setTokenIsValid(true);
    }

    const clearTokens = () => {
        idm.clearAllTokens();
        setIsLoggedIn(false);
        setTokenIsValid(false);
    }

    const handleExpire = () => {
        clearTokens();
        sessionChanged(false);
    }

    const switchTheme = () => {
        const body = document.getElementsByTagName("body")[0];
        const themeRegex = /^theme\-\d+/g;
        let currentThemeNumber = null;
        
        [...body.classList].forEach(className => {
            let classMatch = className.match(themeRegex);

            if(classMatch) {
                currentThemeNumber = classMatch[0].split("-")[1];
            }
        });

        if(currentThemeNumber) {
            if(currentThemeNumber >= config.app.total_themes) {
                setThemeNumber(1);
            } else {
                setThemeNumber(Number(currentThemeNumber) + 1);
            }
        }
    }

    const showMenu = topic => {
        setSecondaryMenuVisible(false);
        if(topic == "recipes") {
            setRecipeNavVisible(true);
        } else if(topic === "spirits") {
            setSpiritsNavVisible(true);
        } else if(topic === "types") {
            setTypesNavVisible(true);
        } else if(topic === "ingredients") {
            setIngredientsNavVisible(true);
        } else if(topic === "tags") {
            setTagsNavVisible(true);
        } else if(topic === "orders") {
            setOrderNavVisible(true);
        } else if(topic === "patrons") {
            setPatronNavVisible(true);
        } else if(topic === "locations") {
            setLocationNavVisible(true);
        } else if(topic === "orders-spirits") {
            setOrderSpiritsNavVisible(true);
        } else if(topic === "usermenu") {
            setUserMenuNavVisible(true);
        } else if(topic === "share") {
            setShareNavVisible(true);
        } else if(topic === "password") {
            setPasswordNavVisible(true);
        } else if(topic === "metrics") {
            setMetricsNavVisible(true);
        } else if(topic === "metrics-patrons") {
            setMetricsPatronsNavVisible(true);
        } else if(topic === "about") {
            setAboutNavVisible(true);
        }

        const body = document.getElementsByTagName("body")[0];
        body.classList.add("noscroll");
    }

    const hideMenu = topic => {
        if(topic == "recipes") {
            setRecipeNavVisible(false);
        } else if(topic == "spirits") {
            setSpiritsNavVisible(false);
        } else if(topic == "types") {
            setTypesNavVisible(false);
        } else if(topic == "ingredients") {
            setIngredientsNavVisible(false);
        } else if(topic === "tags") {
            setTagsNavVisible(false);
        } else if(topic == "orders") {
            setOrderNavVisible(false);
        } else if(topic == "patrons") {
            setPatronNavVisible(false);
        } else if(topic === "locations") {
            setLocationNavVisible(false);
        } else if(topic === "orders-spirits") {
            setOrderSpiritsNavVisible(false);
        } else if(topic == "usermenu") {
            setUserMenuNavVisible(false);
        } else if(topic === "share") {
            setShareNavVisible(false);
        } else if(topic === "password") {
            setPasswordNavVisible(false);
        } else if(topic === "metrics") {
            setMetricsNavVisible(false);
        } else if(topic === "metrics-patrons") {
            setMetricsPatronsNavVisible(false);
        } else if(topic === "about") {
            setAboutNavVisible(false);
        }

        const body = document.getElementsByTagName("body")[0];
        body.classList.remove("noscroll");
    }

    const navTo = topic => {
        if(topic === "logout") {
            clearTokens();
        } else {
            showMenu(topic);
        }
    }

    const showSecondaryMenu = () => {
        setSecondaryMenuVisible(true);
        document.getElementsByTagName("body")[0].classList.add("noscroll");
    }

    const hideSecondaryMenu = () => {
        setSecondaryMenuVisible(false);
        document.getElementsByTagName("body")[0].classList.remove("noscroll");
    }

    const LoginHeader = () => {
        if(idm.isLoggedIn()) {
            return (
                <div className="header-item icon icon-right" onClick={() => {
                    showMenu("usermenu");
                }}>
                    <i className="fa-regular fa-id-badge" />
                </div>
            );
        } else {
            return (
                <div className="header-item icon icon-right" onClick={() => {
                    showLogin();
                }}>
                    <i className="fa-solid fa-fingerprint" />
                </div>
            );
        }
    }

    const MainHeader = () => {
        let headerLabel = "Undef";
        let headerIcon = "question";

        if(screen === "home") {
            headerLabel = "Featured";
            headerIcon = "newspaper";
        } else if(screen === "recipes") {
            headerLabel = "Drinks";
            headerIcon = "whiskey-glass";
        } else if(screen === "orders") {
            headerLabel = "Orders";
            headerIcon = "hand-point-up";
        } else if(screen === "metrics") {
            headerLabel = "Metrics";
            headerIcon = "chart-simple";
        }

        return (
            <div className="header-main" onClick={() => {
                if(screen === "home") {
                    navigate("/");
                } else {
                    showMenu(screen);
                }
            }}>
                <div className="header-main-icon">
                    <i className={`fa-solid fa-${headerIcon}`} />
                </div>
                <div className="header-main-text">
                    <span>{headerLabel}</span>
                </div>
            </div>
        );
    }

    // setup the off click typeahead hider
    useOutsideBlur(menuRef, hideSecondaryMenu);

    return (
        <>
            <div className="header-spacer"></div>
            <div id="app-header" className="header" ref={menuRef}>
                <MainHeader />
                <div className="header-fill"></div>
                <div className={`header-item icon icon-right ${screen === "home" ? "selected" : ""}`} onClick={() => {
                    navigate("/");
                }}>
                    <i className="fa-solid fa-newspaper" />
                </div>
                <div className={`header-item icon icon-right ${screen === "recipes" ? "selected" : ""}`} onClick={() => {
                    showMenu("recipes");
                }}>
                    <i className="fa-solid fa-whiskey-glass" />
                </div>
                <div className={`header-item icon icon-right ${screen === "orders" ? "selected" : ""}`} onClick={() => {
                    showMenu("orders");
                }}>
                    <i className="fa-solid fa-hand-point-up" />
                </div>
                <div className={`header-item icon icon-right ${screen === "metrics" ? "selected" : ""}`} onClick={() => {
                    showMenu("metrics");
                }}>
                    <i className="fa-solid fa-chart-simple" />
                </div>
                <LoginHeader />
                <div className="header-item icon icon-right" onClick={() => {
                    if(secondaryMenuVisible) {
                        hideSecondaryMenu();
                    } else {
                        showSecondaryMenu();
                    }
                }}>
                    <i className="fa-solid fa-ellipsis-vertical" />
                </div>
                <div className={`secondary-menu ${secondaryMenuVisible ? "show" : "hide"}`}>
                    <div className="filler"></div>
                    <div className="menu-content">
                        <ul>
                            <li onClick={switchTheme}>
                                <i className="fa-solid fa-palette" />
                                <span>Switch App Color</span>
                            </li>
                            <li onClick={() => { showMenu("about"); }}>
                                <i className="fa-solid fa-code-branch" />
                                <span>Nerd Stuff lol</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Login visible={loginVisible} setVisible={setLoginVisible} loginSuccessful={loginSuccessful} setTokenIsValid={setTokenIsValid} handleExpire={handleExpire} />
            <NavModal type="recipes" visible={ recipeNavVisible } hideMenu={() => { hideMenu("recipes") } } navTo={ navTo } />
            <NavModal type="spirits" visible={ spiritsNavVisible } hideMenu={() => { hideMenu("spirits") } } navTo={ navTo } />
            <NavModal type="types" visible={ typesNavVisible } hideMenu={() => { hideMenu("types") } } navTo={ navTo } />
            <NavModal type="ingredients" visible={ ingredientsNavVisible } hideMenu={() => { hideMenu("ingredients") } } navTo={ navTo } />
            <NavModal type="tags" visible={ tagsNavVisible } hideMenu={() => { hideMenu("tags") } } navTo={ navTo } />
            <NavModal type="orders" visible={ orderNavVisible } hideMenu={() => { hideMenu("orders") } } navTo={ navTo } />
            <NavModal type="patrons" visible={ patronNavVisible } hideMenu={() => { hideMenu("patrons") } } navTo={ navTo } />
            <NavModal type="locations" visible={ locationNavVisible } hideMenu={() => { hideMenu("locations") } } navTo={ navTo } />
            <NavModal type="orders-spirits" visible={ orderSpiritsNavVisible } hideMenu={() => { hideMenu("orders-spirits") } } navTo={ navTo } />
            <NavModal type="usermenu" visible={ userMenuNavVisible } hideMenu={() => { hideMenu("usermenu") } } navTo={ navTo } />
            <NavModal type="share" visible={ shareNavVisible } hideMenu={() => { hideMenu("share") } } navTo={ navTo } />
            <NavModal type="password" visible={ passwordNavVisible } hideMenu={() => { hideMenu("password") } } navTo={ navTo } />
            <NavModal type="metrics" visible={ metricsNavVisible } hideMenu={() => { hideMenu("metrics") } } navTo={ navTo } />
            <NavModal type="metrics-patrons" visible={ metricsPatronsNavVisible } hideMenu={() => { hideMenu("metrics-patrons") } } navTo={ navTo } />
            <NavModal type="about" visible={ aboutNavVisible } hideMenu={() => { hideMenu("about") } } navTo={ navTo } />
            <AlertModal />
            <ConfirmModal />
            <div className={`app-cover ${secondaryMenuVisible ? "show" : "hide"}`}></div>
        </>
    );
}

export default Header;