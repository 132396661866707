import React, {useState, useEffect, useReducer} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useParams, useNavigate, useSearchParams} from 'react-router-dom';
import SearchBox from './SearchBox';
import RecipeList from './RecipeList';
import Modal from './Modal';
import Pagination from './Pagination';
import RecipeSortMenu from './RecipeSortMenu';

import config from './helper/config';
import idm from './helper/idm';
import ws from './helper/ws';

import {set as setRecipes} from './reducers/recipesSlice';

import styles from './css/RecipeSearch.css';

const reducer = (state, action) => {
    switch(action.type) {
        case 'set':
            return {...state, ...action.setTo};
        default:
            throw new Error("action.type is not valid");
    }
}
 
const RecipeSearch = React.memo(({ searchType }) => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    //const [recipes, setRecipeList] = useState(null);
    const [recipe, setRecipe] = useState({});
    const [addModalIsVisible, setAddModalIsVisible] = useState(false);
    const [detailModalIsVisible, setDetailModalIsVisible] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [recipeCache, setRecipeCache] = useState([]);
    const [error, setError] = useState(null);
    const recipes = useSelector((state) => state.recipes.list);
    const resultsPerPage = config.app.results_per_page;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sortList = {
        "score" : {
            "display" : "Relevance",
            "field" : "_score",
            "direction" : "desc"
        },
        "name" : {
            "display" : "Drink Name",
            "field" : "name.keyword",
            "direction" : "asc"
        },
        "ordered" : {
            "display" : "Most Ordered",
            "field" : "order_count",
            "direction" : "desc"
        },
        "rating_high" : {
            "display" : "Rating (Highest)",
            "field" : "average_rating",
            "direction" : "desc"
        },
        "rating_low" : {
            "display" : "Rating (Lowest)",
            "field" : "average_rating",
            "direction" : "asc"
        },
        "added_newer" : {
            "display" : "Added On (Newer)",
            "field" : "added",
            "direction" : "desc"
        },
        "added_older" : {
            "display" : "Added On (Older)",
            "field" : "added",
            "direction" : "asc"
        },
        "ordered_newer" : {
            "display" : "Ordered On (Newer)",
            "field" : "last_ordered",
            "direction" : "desc"
        },
        "ordered_older" : {
            "display" : "Ordered On (Older)",
            "field" : "last_ordered",
            "direction" : "asc"
        }
    }
    const [sort, setSort] = useState((() => {
        if(sortList[searchParams.get("s")]) {
            return searchParams.get("s");
        } else if(sortList[localStorage.getItem('recipeSearchSort')]) {
            return localStorage.getItem('recipeSearchSort');
        } else {
            return 'ordered';
        }
    })());
    const initialState = {
        "searchType" : searchType,
        "spirit" : params.spirit ?? null,
        "glassType" : params.glassType ?? null,
        "garnish" : params.garnish ?? null,
        "tag" : params.tag ?? null,
        "cocktailType" : params.type ?? null,
        "searchText" : searchParams.get("q") ||  '',
        "page" : searchParams.get("p") ? Number(searchParams.get("p")) : 1,
        "sort" : (() => {
            if(sortList[searchParams.get("s")]) {
                return searchParams.get("s");
            } else if(sortList[localStorage.getItem('recipeSearchSort')]) {
                return localStorage.getItem('recipeSearchSort');
            } else {
                return 'ordered';
            }
        })()
    }
    const [state, setState] = useReducer(reducer, initialState);
    const ingredients = searchParams.get("ingredients");
    const currentPath = window.location.pathname;

    // load the recipe cache (all documents with limited fields)
    // this will facilitate client-side searching (typeahead)
    // routine will run once (on component load)
    useEffect(() => {
        let path = "/recipes/cache";
        let method = "GET";
        let query = null;

        if(state.searchType === "favorites") {
            path += "/favorites";
        } else if(state.searchType === "seasonal") {
            path += "/seasonal";
        } else if(state.searchType === "types") {
            path += `/types/${state.cocktailType}`;
        } else if(state.searchType === "spirits") {
            path += `/spirits/${state.spirit}`;
        } else if(state.searchType === "glass-types") {
            path += `/glasses/${state.glassType}`;
        } else if(state.searchType === "garnishes") {
            path += `/garnishes/${state.garnish}`;
        } else if(state.searchType === "tags") {
            path += `/tags/${state.tag}`;
        } else if(state.searchType === "ingredients") {
            path += "/ingredients";
            method = "POST";
            query = {
                "ingredients" : ingredients.split(","),
                "loose_match" : false
            }
        }

        ws.apiRequest({
            "method" : method,
            "path" : path,
            "params" : {
                "size" : 1000
            },
            "data" : query
        }).then(output => {
            setRecipeCache(output.data.response);
        }).catch(e => {
            console.error(e);
        });
    }, [state.searchType]);

    /*
    * Execute a search whenever the sort, page, or search text changes
    */
   /*
    useEffect(() => {
        console.log("sort, page, or search text update");
        executeSearch();
        navigate(calculateRoute());
    }, [sort, page, recipeSearchText]);
    */

    useEffect(() => {
        executeSearch();
        navigate(calculateRoute());
    }, [state]);

    useEffect(() => {
        setState({
            type: "set",
            setTo: {
                "searchType" : searchType,
                "spirit" : params.spirit,
                "glassType" : params.glassType,
                "garnish" : params.garnish,
                "tag" : params.tag,
                "cocktailType" : params.type
            }
        });
    }, [searchType, params.spirit, params.glassType, params.garnish, params.tag, params.type]);

    const setRecipeList = list => {
        dispatch(setRecipes(list));
    }

    /*
    * state setting from child components
    */
    const setSearchText = text => {
        setState({
            type: "set",
            setTo: {
                "searchText": text,
                "page": 1
            }
        });
    }

    const setSortText = text => {
        setState({
            type: "set",
            setTo: {
                "sort": text,
                "page": 1
            }
        });
    }

    const setPageNum = (pageNum) => {
        if(state.page == pageNum) {
            return;
        }

        setState({
            type: "set",
            setTo: {
                "page": pageNum
            }
        });
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }


    const filterText = text => {
        const regex1 = new RegExp(text);
        const regex2 = new RegExp(`${text.split(/\s+/g).join("|")}`, "gi");
        return recipeCache.filter(recipe => 
                regex1.test(recipe.name) ||
                regex2.test(recipe.name) ||
                regex1.test(recipe.description) ||
                regex2.test(recipe.description) ||
                regex1.test(recipe.primary_spirit) ||
                regex2.test(recipe.primary_spirit)
            ).map(recipe => {
                return recipe.name;
            }
        ).sort();
    }


    const calculateRoute = () => {
        let qs = {};
        searchParams.forEach((value, key) => {
            qs[key] = value;
        });

        qs.q = state.searchText;
        qs.p = state.page;
        qs.s = state.sort;

        let kv = Object.keys(qs).map(key => {
            return key + "=" + qs[key];
        });

        return currentPath + "?" + kv.join("&");
    }


    const executeSearch = () => {
        let sortBy = null;
        let sortDir = null;
        let path = "/recipes";
        let method = "GET";
        let query = {};

        if(state.sort) {
            sortBy = sortList[state.sort].field;
            sortDir = sortList[state.sort].direction;
        }

        if(state.searchType === "favorites") {
            path += "/favorites";
        } else if(state.searchType === "seasonal") {
            path += "/seasonal";
        } else if(state.searchType === "types") {
            path += `/types/${state.cocktailType}`;
        } else if(state.searchType === "spirits") {
            path += `/spirits/${state.spirit}`;
        } else if(state.searchType === "glass-types") {
            path += `/glass-types/${state.glassType}`;
        } else if(state.searchType === "garnishes") {
            path += `/garnishes/${state.garnish}`;
        } else if(state.searchType === "tags") {
            path += `/tags/${state.tag}`;
        } else if(state.searchType === "ingredients") {
            path += "/ingredients";
            method = "POST";
            query = {
                "ingredients" : ingredients.split(","),
                "loose_match" : false
            }
        }

        /*
        console.log({
            "type" : "recipe search",
            "recipeSearchText" : recipeSearchText,
            "page" : page,
            "sort" : sort,
            "searchType" : searchType,
            "route" : calculateRoute()
        });
        */

        ws.call({
            "method" : method,
            "path" : path,
            "params" : {
                "q" : state.searchText,
                "size" : resultsPerPage,
                "from" : ((state.page - 1) * resultsPerPage),
                "sort" : sortBy,
                "direction" : sortDir
            },
            "data" : query
        }).then(output => {
            setError(null);

            if(state.searchType === "ingredients") {
                if(output.response.results.length == totalResults) {
                    setRecipeList([]);
                }
                setRecipeList(output.response.results);
                setTotalResults(output.response.hits);
            } else {
                if(output.response.length == totalResults) {
                    setRecipeList([]);
                }
                setRecipeList(output.response);
                setTotalResults(output.hits);
            }
        }).catch(e => {
            console.log(e);
            setError("Recipe search is not available");
        });
    };

    const handleRecipeSaved = (recipeDoc, actionType) => {
        setRecipe({});
        //setTimeout(() => {
            executeSearch(); // re-execute search with recipe changing
        //}, 500);

        if(actionType == "added") {
            setDetailModalIsVisible(true);
        }
    }

    const showAddModal = () => {
        //document.getElementsByTagName("body")[0].classList.add("noscroll");
        setAddModalIsVisible(true);
    }

    const hideAddModal = () => {
        //document.getElementsByTagName("body")[0].classList.remove("noscroll");
        setAddModalIsVisible(false);
        setRecipe({});
    }

    const showDetailModal = (recipe) => {
        //document.getElementsByTagName("body")[0].classList.add("noscroll");
        setRecipe(recipe);
        setDetailModalIsVisible(true);
    }

    const hideDetailModal = () => {
        //document.getElementsByTagName("body")[0].classList.remove("noscroll");
        setDetailModalIsVisible(false);
    }

    const showRecipeEdit = ({ recipeToEdit, hideRecipeModal }) => {
        setRecipe(recipeToEdit);
        showAddModal();

        if(hideRecipeModal) {
            hideRecipeModal();
        }
    }

    const refreshSearch = () => {
        executeSearch();
    }

    const AddButton = () => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return "";
        }

        return (
            <div className="add-container">
                <button onClick={() => {
                    showAddModal();
                }}><i className="fa-solid fa-square-plus" /> Add Recipe</button>
            </div>
        );
    }

    const SearchResults = () => {
        if(error) {
            return <div className="search-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        return <RecipeList recipes={recipes} showRecipeEdit={showRecipeEdit} refreshSearch={refreshSearch} executed={recipes === null ? false : true} />
    }
    
    return (
        <div key={`recipe-search-${state.searchType ? state.searchType : "regular"}`}>
            {
                (() => {
                    if(state.searchType === "favorites") {
                        return (
                            <div className="search-type">Showing: Local Favorites<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "seasonal") {
                        return (
                            <div className="search-type">Showing: Seasonal Drinks<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "types") {
                        return (
                            <div className="search-type">Showing: {state.cocktailType}<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "spirits") {
                        return (
                            <div className="search-type">Showing By Spirit: {state.spirit}<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "glass-types") {
                        return (
                            <div className="search-type">Showing By Glass Type: {state.glassType}<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "garnishes") {
                        return (
                            <div className="search-type">Showing By Garnish: {state.garnish}<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "tags") {
                        return (
                            <div className="search-type">Showing By Tag: {state.tag}<div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    } else if(state.searchType === "ingredients") {
                        return (
                            <div className="search-type">Ingredients: <span className="ingredient-list">{ingredients ? ingredients.split(",").join(", ") : "None Specified"}</span><div onClick={() => {
                                navigate("/recipes");
                            }} className="close" /></div>
                        )
                    }
                })()
            }

            <SearchBox placeholderText="Search Drinks" searchType="recipes" searchText={state.searchText} setSearchText={setSearchText} filterText={filterText} />

            <div className="recipe-search-options-container">
                <RecipeSortMenu sortList={sortList} sort={state.sort} handleSort={setSortText} />
                <AddButton />
            </div>

            <SearchResults />

            <Pagination listType="recipe" currentPage={state.page} totalPages={Math.ceil(totalResults / resultsPerPage)} totalResults={totalResults} resultsPerPage={resultsPerPage} goToPage={setPageNum} />
            <Modal displayComponent="recipe-add" modalId={"recipe-add"} visible={addModalIsVisible} hideModal={hideAddModal} recipe={recipe} showDetail={showDetailModal} setRecipe={handleRecipeSaved} />
            {/*<Modal displayComponent="recipe" modalId={"recipe-detail"} recipe={recipe} visible={detailModalIsVisible} showRecipeEdit={showRecipeEdit} hideModal={hideDetailModal} />*/}
        </div>
    );
});

export default RecipeSearch;