import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
//import fastyles from './fa/css/all.css';
import axios from 'axios';

import TypeaheadField from './TypeaheadField';

import app from './helper/app';
import ws from './helper/ws';
import idm from './helper/idm';
import cocktail from './helper/cocktail';

import localstyles from './css/SpiritsActual.css';


const SpiritsActual = ({ order, edit=false, setOrder=() => {} }) => {
    const [spiritsCache, setSpiritsCache] = useState(null);
    const [editMode, setEditMode] = useState(edit);
    
    const navigate = useNavigate();

    useEffect(() => {
        if(!editMode || spiritsCache) {
            return;
        }

        let _cache = {};

        order.spirits.forEach(spirit => {
            ws.apiRequest({
                "method" : "GET",
                "path" : `/orders/actual-spirits/type/${spirit}`
            }).then(output => {
                _cache[spirit] = output.data.response.map(actualSpirit => {
                    return actualSpirit.spirit;
                });

                setSpiritsCache(_cache);
            }).catch(e => {
                console.error(e);
            });
        });
    }, [editMode]);

    useEffect(() => {
    }, [spiritsCache]);

    const filterSpirits = (text, spiritType) => {
        const regex1 = new RegExp(text, "gi");
        return spiritsCache[spiritType].filter(spirit => regex1.test(spirit)).sort();
    }

    const Spirit = ({ spirit }) => {
        if(!spirit) {
            return "";
        }

        return (
            <>
                <div className="link-container">
                    <a
                        key={`${order.id}-${spirit.type}`}
                        onClick={() => {
                            navigate(`/orders/spirits/${spirit.type}`);
                        }}
                    >
                        {spirit.type}
                    </a>:
                </div>
                <div className="link-container">
                    {
                        (() => {
                            if(!spirit.used) {
                                return "";
                            }

                            return <a
                                key={`${order.id}-${spirit.used}`}
                                onClick={() => {
                                    navigate(`/orders/actual-spirits/${spirit.used}`);
                                }}
                            >
                                {spirit.used}
                            </a>
                        })()
                    }
                </div>
            </>
        )
    }

    const ExitEditButton = () => {
        if(idm.tokenHasRole("order_admin") && !order.actual_spirits) {
            return <></>
        }

        return <button onClick={() => {
            setEditMode(false);
        }}><i className="fa-solid fa-xmark" /></button>
    }

    const Detail = () => {
        let actualSpirits = order.actual_spirits ?? [];
        let exists = false;

        actualSpirits.forEach(spiritType => {
            if(spiritType.used) {
                exists = true;
            }
        });

        const EditButton = () => {
            if(!idm.tokenHasRole("order_admin")) {
                return <></>
            }

            return (
                <button onClick={() => {
                    setEditMode(true);
                }}><i className="fa-solid fa-edit" /></button>
            );
        }

        if(!idm.tokenHasRole("order_admin") && !exists) {
            return <></>
        }

        return (
            <div className="spirits-actual">
                <div className="spirits-actual-title">
                    Spirits Used:
                </div>
                <div className="title-actions">
                    <EditButton />
                </div>
                {
                    actualSpirits.map(spirit => {
                        return <div
                            key={spirit.type + "-" + order.id}
                            className="spirit-actual"
                        >
                            <Spirit spirit={spirit} />
                        </div>
                    })
                }
                <hr />
            </div>
        );
    }

    const Edit = () => {
        let actual_spirits = order.actual_spirits ?? [];

        if(!idm.tokenHasRole("order_admin")) {
            return <></>
        }

        return (
            <div className="spirits-actual">
                <div className="spirits-actual-title">
                    Spirits Used:
                </div>
                <div className="title-actions">

                </div>
                <div className="order-update-text">
                    {
                        order.spirits.map(spiritType => {
                            return (
                                <TypeaheadField
                                    key={`actual_spirit-${spiritType}-${order.id}`}
                                    fieldId={`actual_spirit-${spiritType}-${order.id}`}
                                    containerClass="field-container ta-container"
                                    className="actual-spirit"
                                    defaultValue={
                                        actual_spirits.filter(spirit => spirit.type == spiritType).map(spirit => {
                                            return spirit.used;
                                        })
                                    }
                                    placeholder={spiritType}
                                    filterMatches={text => {
                                        return filterSpirits(text, spiritType);
                                    }}
                                    showAllButton={true}
                                    showLastButton={true}
                                    lastValue={getLastUsedSpirit}
                                />
                            )
                        })
                    }
                    <div className="spirits-actual-actions button-group">
                        <button className="primary" onClick={() => {
                            updateActualSpirits();
                        }}><i className="fa-solid fa-floppy-disk"></i> Save</button>
                        <button onClick={() => {
                            resetActualSpirits();
                        }}><i className="fa-solid fa-rotate-left" /></button>
                        <ExitEditButton />
                    </div>
                </div>
                <hr />
            </div>
        );
    }

    const updateActualSpirits = () => {
        let _actualSpirits = {};
        order.spirits.forEach(spiritType => {
            _actualSpirits[spiritType] = document.getElementById(`actual_spirit-${spiritType}-${order.id}`).value
        });

        cocktail.orderUpdate({
            "order" : order,
            "id" : order.id,
            "actualSpirits" : _actualSpirits,
            "callback" : orderDoc => {
                setOrder(orderDoc);
                setEditMode(false);
            },
            "error" : err => {
                console.error(err);
            }
        });
    }

    const resetActualSpirits = () => {
        order.spirits.forEach(spiritType => {
            document.getElementById(`actual_spirit-${spiritType}-${order.id}`).value = (() => {
                if(order.actual_spirits) {
                    return order.actual_spirits.find(actualSpirit => actualSpirit.type == spiritType);
                }
                
                return {"used" : ""};
            })().used;
        });
    }

    const getLastUsedSpirit = spiritType => {
        return new Promise((resolve, reject) => {
            cocktail.getOrdersByRecipe(order.recipe_id).then(response => {
                response.response.forEach(result => {
                    if(result.actual_spirits) {
                        if(result.id != order.id) {
                            try {
                                let match = result.actual_spirits.filter(actualSpirit => actualSpirit.type == spiritType)[0];

                                if(match.used) {
                                    resolve(match.used);
                                }
                            } catch(e) {

                            }
                        }
                    }
                });

                // if not results could be found (e.g.: first time an order was submitted)
                // then search all orders with the specific type of spirit as its primary
                cocktail.getOrdersBySpirit(order.primary_spirit).then(response => {
                    response.response.forEach(result => {
                        if(result.actual_spirits) {
                            if(result.id != order.id) {
                                try {
                                    let match = result.actual_spirits.filter(actualSpirit => actualSpirit.type == spiritType)[0];
    
                                    if(match.used) {
                                        resolve(match.used);
                                    }
                                } catch(e) {
    
                                }
                            }
                        }
                    });
                }).catch(e => {
                    console.error(e);
                    reject(e);
                });
            }).catch(e => {
                console.error(e);
                reject(e);
            });
        });
    }

    const render = () => {
        if(editMode) {
            return <Edit />
        } else {
            return <Detail />
        }
    }

    if(idm.tokenHasRole("order_admin") && !order.actual_spirits && !editMode) {
        setEditMode(true);
    }

    return render();
}

export default SpiritsActual;